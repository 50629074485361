import { Helmet } from 'react-helmet';
import ErrorComponent from '../../Components/ErrorComponent/ErrorComponent';

function ErrorPage() {
  return (
    <div className="page-container">
      <div className="page-info">
        <Helmet title="404" />
        <div className="inner-part-B">
          <ErrorComponent />
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;
