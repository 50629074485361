import { Component } from 'react';
import autobind from 'autobind-decorator';
import * as session from 'modules/session';
import productHelper from 'modules/helpers/product-helper';
import api from 'modules/helpers/api';
import { afterCartItem, getProductIds } from 'modules/helpers/cart-helper';
import { analytics } from 'frontend/analytics/analytics';
import {
  getEcomDataToAnalytics,
  getFbPixelDataToCart,
} from 'frontend/analytics/analyticsHelper';
import Emitter from '../Components/NewCartOrder/Emitters';

class CardContainer extends Component {
  componentDidMount() {
    Emitter.addListener('CART_CHANGE_QUANTITY', this.cartQuantityHandler);
  }

  componentWillUnmount() {
    Emitter.removeListener('CART_CHANGE_QUANTITY', this.cartQuantityHandler);
  }

  @autobind
  cartQuantityHandler({ id, qty, child }) {
    const { t, countryCurrency } = this.props;
    if (qty > 0) {
      const cart = session.get('cart');
      const products = getProductIds(cart);
      if (!products.includes(id)) {
        analytics.productCartDesire();
      }
    }
    api('cart.item', { id, qty, child }).then((cart = {}) => {
      afterCartItem({ newCart: cart, t, item: { id, qty, child } });
    });

    const actionTypeToAnalytics = qty > 0 ? 'add' : 'remove';
    analytics.ecom(
      actionTypeToAnalytics,
      getEcomDataToAnalytics({
        product: productHelper.getCartProduct(id),
        qty,
      }),
      countryCurrency
    );
    analytics.fbPixel(
      'AddToCart',
      getFbPixelDataToCart(productHelper.getCartProduct(id), countryCurrency)
    );
  }
}

export default CardContainer;
