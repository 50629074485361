import globalStore from 'modules/global-store';
import { ICity } from 'src/entities/cities';

export const useMapDisabled = () => {
  const city: ICity = globalStore.get('current_city');

  const { settings } = city;

  return !!settings.map_disabled;
};
