import globalStore from 'modules/global-store';
import { ICity } from 'src/entities/cities';
import { useSelectCountry } from 'src/entities/countries';

export const useMapType = () => {
  const { map_type: mapTypeByCity }: ICity = globalStore.get('current_city');
  const { map_type: mapTypeByCountry } = useSelectCountry();
  const mapType = mapTypeByCity || mapTypeByCountry;

  const yandexMapCode = 1;
  const googleMapCode = 2;

  return {
    isYandexMap: mapType === yandexMapCode,
    isGoogleMap: mapType === googleMapCode,
  };
};
