import './About.scss';
import './Platforms/Platforms.scss';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import HeroRun1Image from 'assets/images/about/Platforms/Zal/hero-run-1.png';
import HeroRun2Image from 'assets/images/about/Platforms/Zal/hero-run-2.png';
import HeroBuy1Image from 'assets/images/about/Platforms/Zal/hero-buy-1.png';
import HeroBuy2Image from 'assets/images/about/Platforms/Zal/hero-buy-2.png';
import HeroBuy3Image from 'assets/images/about/Platforms/Zal/hero-buy-3.png';
import HeroBuy4Image from 'assets/images/about/Platforms/Zal/hero-buy-4.png';
import HeroOk1Image from 'assets/images/about/Platforms/Zal/hero-ok-1.png';
import HeroOk2Image from 'assets/images/about/Platforms/Zal/hero-ok-2.png';
import HeroOk3Image from 'assets/images/about/Platforms/Zal/hero-ok-3.png';
import Hero00 from 'assets/images/about/Platforms/Prizes/hero-0-0.png';
import Hero10 from 'assets/images/about/Platforms/Prizes/hero-1-0.png';
import Hero20 from 'assets/images/about/Platforms/Prizes/hero-2-0.png';
import Hero30 from 'assets/images/about/Platforms/Prizes/hero-3-0.png';
import Hero40 from 'assets/images/about/Platforms/Prizes/hero-4-0.png';
import Prizes00 from 'assets/images/about/Platforms/Prizes/prizes-0-0.png';
import Prizes10 from 'assets/images/about/Platforms/Prizes/prizes-1-0.png';
import Prizes20 from 'assets/images/about/Platforms/Prizes/prizes-2-0.png';
import Prizes30 from 'assets/images/about/Platforms/Prizes/prizes-3-0.png';
import Prizes40 from 'assets/images/about/Platforms/Prizes/prizes-4-0.png';
import Prizes50 from 'assets/images/about/Platforms/Prizes/prizes-5-0.png';
import Prizes60 from 'assets/images/about/Platforms/Prizes/prizes-6-0.png';
import Man00Image from 'assets/images/about/Platforms/Prizes/man-0-0.png';
import Man10Image from 'assets/images/about/Platforms/Prizes/man-1-0.png';
import Man20Image from 'assets/images/about/Platforms/Prizes/man-2-0.png';
import Hero1 from 'assets/images/about/Platforms/Vacancy/hero_1.png';
import Hero2 from 'assets/images/about/Platforms/Vacancy/hero_2.png';
import Hero3 from 'assets/images/about/Platforms/Vacancy/hero_3.png';
import Hero4 from 'assets/images/about/Platforms/Vacancy/hero_4.png';
import Hand1 from 'assets/images/about/Platforms/Vacancy/hand_01.png';
import Hand2 from 'assets/images/about/Platforms/Vacancy/hand_02.png';
import Hand3 from 'assets/images/about/Platforms/Vacancy/hand_03.png';
import Hand4 from 'assets/images/about/Platforms/Vacancy/hand_04.png';
import Hand5 from 'assets/images/about/Platforms/Vacancy/hand_05.png';
import Hand6 from 'assets/images/about/Platforms/Vacancy/hand_06.png';
import Hand7 from 'assets/images/about/Platforms/Vacancy/hand_07.png';
import Hand8 from 'assets/images/about/Platforms/Vacancy/hand_08.png';
import KitchenHeroRun00 from 'assets/images/about/Platforms/Kitchen/hero-run-0-0.png';
import KitchenHeroRun10 from 'assets/images/about/Platforms/Kitchen/hero-run-1-0.png';
import KitchenHero10 from 'assets/images/about/Platforms/Kitchen/hero-1-0.png';
import KitchenHero00 from 'assets/images/about/Platforms/Kitchen/hero-0-0.png';
import KitchenChief00 from 'assets/images/about/Platforms/Kitchen/chief-0-0.png';
import KitchenChief10 from 'assets/images/about/Platforms/Kitchen/chief-1-0.png';
import KitchenChief20 from 'assets/images/about/Platforms/Kitchen/chief-2-0.png';
import ActionsParashute1 from 'assets/images/about/Platforms/Actions/parashute-1.png';
import ActionsParashute2 from 'assets/images/about/Platforms/Actions/parashute-2.png';
import ActionsParashute3 from 'assets/images/about/Platforms/Actions/parashute-3.png';
import ActionsGiftMan00 from 'assets/images/about/Platforms/Actions/gift-man-0-0.png';
import ActionsGiftMan10 from 'assets/images/about/Platforms/Actions/gift-man-1-0.png';
import ActionsGiftMan20 from 'assets/images/about/Platforms/Actions/gift-man-2-0.png';
import ActionsHero00 from 'assets/images/about/Platforms/Actions/hero-0-0.png';
import ActionsHero10 from 'assets/images/about/Platforms/Actions/hero-1-0.png';
import ActionsHero20 from 'assets/images/about/Platforms/Actions/hero-2-0.png';
import ActionsHero30 from 'assets/images/about/Platforms/Actions/hero-3-0.png';
import ShopsMarker from 'assets/images/about/Platforms/Shops/marker.svg?url';
import ShopsHeroRun00 from 'assets/images/about/Platforms/Shops/hero-run-0-0.png';
import ShopsHeroRun10 from 'assets/images/about/Platforms/Shops/hero-run-1-0.png';
import ShopsHero00 from 'assets/images/about/Platforms/Shops/hero-0-0.png';
import ShopsHero10 from 'assets/images/about/Platforms/Shops/hero-1-0.png';
import GirlHero00 from 'assets/images/about/Platforms/Girl/hero-0-0.png';
import GirlHero10 from 'assets/images/about/Platforms/Girl/hero-1-0.png';
import GirlHero20 from 'assets/images/about/Platforms/Girl/hero-2-0.png';
import GirlHero30 from 'assets/images/about/Platforms/Girl/hero-3-0.png';
import GirlHero40 from 'assets/images/about/Platforms/Girl/hero-4-0.png';
import NewsGlobus from 'assets/images/about/Platforms/News/globus.png';
import NewsRuporShadow from 'assets/images/about/Platforms/News/rupor-shadow.png';
import NewsRupor from 'assets/images/about/Platforms/News/rupor.png';
import NewsHeroRun from 'assets/images/about/Platforms/News/hero-run.png';
import NewsHeroSit1 from 'assets/images/about/Platforms/News/hero-sit-1.png';
import NewsHeroSit2 from 'assets/images/about/Platforms/News/hero-sit-2.png';
import BoardHeroLeft10 from 'assets/images/about/Platforms/Board/hero-left-1-0.png';
import overlayImage from 'assets/images/about/Platforms/overlay.png';
import { SmartLink } from 'shared/ui/smart-link';
import CustomText from '../../Components/CustomText/CustomText';
import DynamicLocaleImage from './DynamicLocaleImage';

let animation = null;
let move_animation = null;
let platform_with_animation = null;
let first_move = true;
class About extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hero_on_board: {
        position: {
          bottom: '',
          left: '',
        },
        width: '',
        active: true,
        move: false,
        right: false,
      },
      ready: true,
      speed: 'speed_1',
      current_timer: 4,
      active_patform: '',
      platforms_animation: {
        zal: {
          move_duration: 3500,
        },
        kitchen: {
          move_duration: 3000,
        },
        news: {
          move_duration: 2000,
        },
        shops: {
          move_duration: 3000,
        },
      },
      popupStyle: {
        top: '-100vw',
        left: '-100vw',
        display: 'block',
      },
    };

    this.clickedPlatform = '';
  }

  componentDidMount() {
    this.initHeroOnBoard();
  }

  setActive(platform_name) {
    this.clickedPlatform = platform_name;

    const {
      active_patform,
      current_timer,
      platforms_animation,
      hero_on_board,
      popupStyle,
    } = this.state;
    popupStyle.display = 'none';

    this.setState({
      popupStyle,
    });

    if (active_patform === platform_name && active_patform !== '') {
      return;
    }

    this.setState({
      active_patform: '',
    });

    const platform_in = document.getElementById(`${platform_name}-in`);
    const platform_out = document.getElementById(`${platform_name}-out`);
    const el = document.querySelector(`.platform_${platform_name}`);

    this.setState({
      hero_on_board,
    });

    if (move_animation) {
      const move_hero = document
        .querySelector(`.platform_${platform_with_animation}`)
        .querySelector('.hero_move_animation');
      const move_hero_position = move_hero.getBoundingClientRect();
      const bottom =
        window.innerHeight - move_hero_position.top - move_hero_position.height;
      const { left } = move_hero_position;

      hero_on_board.position.left = left;
      hero_on_board.position.bottom = bottom;
      hero_on_board.no_animate = true;

      this.setState({
        hero_on_board,
      });

      clearInterval(move_animation);
      move_animation = null;
    }

    setTimeout(() => {
      hero_on_board.no_animate = false;
      this.setState({
        hero_on_board,
      });

      const next_timer = el.getAttribute('data-timer');
      let timer_class = Math.abs(
        parseInt(current_timer, 10) - parseInt(next_timer, 10)
      );
      const element_position_data = platform_in.getBoundingClientRect();
      const bottom =
        window.innerHeight -
        element_position_data.top -
        element_position_data.height;
      const { left } = element_position_data;

      if (timer_class < 1) {
        timer_class = 1;
      }

      this.animateAllwaysTopPlatforms(el, timer_class);
      if (active_patform && active_patform !== '') {
        this.animateAllwaysTopPlatforms(
          document.querySelector(`.platform_${active_patform}`),
          timer_class
        );
      }

      this.setState({
        speed: `speed_${timer_class}`,
        current_timer: next_timer,
      });

      hero_on_board.active = true;
      hero_on_board.position.bottom = `${bottom}px`;
      hero_on_board.position.left = `${left}px`;
      hero_on_board.width = `${element_position_data.width}px`;
      const current_left = parseInt(
        document.querySelector('.hero_on_board').style.left,
        10
      );
      hero_on_board.right =
        !first_move &&
        parseInt(current_left, 10) <= parseInt(hero_on_board.position.left, 10);
      first_move = false;

      this.setState({
        hero_on_board,
      });

      if (animation !== null) {
        clearTimeout(animation);
      }

      if (platforms_animation[platform_name]) {
        platform_with_animation = platform_name;

        move_animation = setTimeout(() => {
          clearInterval(move_animation);
          move_animation = null;
        }, platforms_animation[platform_name].move_duration);
      }

      animation = setTimeout(() => {
        if (this.clickedPlatform !== platform_name) {
          return;
        }

        const element_position_data = platform_out.getBoundingClientRect();

        hero_on_board.position.bottom = `${
          window.innerHeight -
          element_position_data.top -
          element_position_data.height
        }px`;
        hero_on_board.position.left = `${element_position_data.left}px`;
        hero_on_board.width = `${element_position_data.width}px`;

        hero_on_board.active = false;
        hero_on_board.move = false;

        this.setState({
          hero_on_board,
          active_patform: platform_name,
        });
      }, (timer_class - 1) * 200 + 1000);
    }, 100);
  }

  getPlatformClass(p) {
    return [
      'platform',
      `platform_${p}`,
      this.state.active_patform === p ? 'active' : '',
      this.state.ready ? 'ready' : '',
    ].join(' ');
  }

  plarformZal() {
    const { t } = this.props;
    return (
      <div
        data-timer="0"
        data-in="27, 62"
        data-out="13, 55"
        className={this.getPlatformClass('zal')}
      >
        <DynamicLocaleImage platformName="Zal" />
        <div id="zal-in" className="platform-in" />
        <div id="zal-out" className="platform-out" />
        <div className="hand" />
        <div className="hero_run hero_move_animation">
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            className="preload"
            src={HeroRun1Image}
            alt=""
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={HeroRun2Image}
            alt=""
          />
          <div className="hero_sprite" />
        </div>
        <div className="hero_buy">
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            className="preload"
            src={HeroBuy1Image}
            alt=""
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={HeroBuy2Image}
            alt=""
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={HeroBuy3Image}
            alt=""
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={HeroBuy4Image}
            alt=""
          />
          <div className="hero_sprite" />
        </div>

        <div className="hero_ok">
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            className="preload show_on_mobile"
            src={HeroOk1Image}
            alt=""
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={HeroOk2Image}
            alt=""
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={HeroOk3Image}
            alt=""
          />

          <div className="hero_sprite" />
        </div>

        <div className="activeZone">
          <div data-platform="zal" className="square" />
        </div>

        <div className="platform-popup">
          <div className="triangle triangle--bottom triangle--bottom--left" />
          <div className="text">{t('About.zal_popup')}</div>
          <span>
            <SmartLink href="/addresses">{t('About.zal_link')}</SmartLink> →
          </span>
        </div>
      </div>
    );
  }

  plarformPrizes() {
    const { t } = this.props;
    return (
      <div
        data-timer="1"
        data-in="31, 67"
        data-out="31, 67"
        className={this.getPlatformClass('prizes')}
      >
        <DynamicLocaleImage platformName="Prizes" />
        <div id="prizes-in" className="platform-in" />
        <div id="prizes-out" className="platform-out" />
        <div className="hero">
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            className="preload show_on_mobile"
            src={Hero00}
            alt=""
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={Hero10}
            alt=""
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={Hero20}
            alt=""
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={Hero30}
            alt=""
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={Hero40}
            alt=""
          />
          <div className="sprite" />
        </div>
        <div className="prizes">
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            className="preload"
            src={Prizes00}
            alt=""
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={Prizes10}
            alt=""
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={Prizes20}
            alt=""
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={Prizes30}
            alt=""
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={Prizes40}
            alt=""
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={Prizes50}
            alt=""
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={Prizes60}
            alt=""
          />
          <div className="sprite" />
        </div>
        <div className="man">
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            className="preload"
            src={Man00Image}
            alt=""
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={Man10Image}
            alt=""
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={Man20Image}
            alt=""
          />
          <div className="sprite" />
        </div>

        <div className="activeZone">
          <div data-platform="prizes" className="square" />
        </div>

        <div className="platform-popup">
          <div className="triangle triangle--right" />
          <div className="text">{t('About.prizes_popup')}</div>
          <span>
            <SmartLink href="/arguments">{t('About.prizes_link')}</SmartLink> →
          </span>
        </div>
      </div>
    );
  }

  platformVacancy() {
    const { t } = this.props;
    return (
      <div
        data-timer="3"
        data-in="77, 58"
        data-out="77, 58"
        className={this.getPlatformClass('vacancy')}
      >
        <DynamicLocaleImage platformName="Vacancy" />
        <div id="vacancy-in" className="platform-in" />
        <div id="vacancy-out" className="platform-out" />
        <div className="hero">
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            className="preload show_on_mobile"
            src={Hero1}
            alt=""
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={Hero2}
            alt=""
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={Hero3}
            alt=""
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={Hero4}
            alt=""
          />
          <div className="sprite" />
        </div>
        <div className="hand">
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            className="preload"
            src={Hand1}
            alt=""
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={Hand2}
            alt=""
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={Hand3}
            alt=""
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={Hand4}
            alt=""
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={Hand5}
            alt=""
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={Hand6}
            alt=""
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={Hand7}
            alt=""
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={Hand8}
            alt=""
          />

          <div className="sprite" />
        </div>

        <div className="activeZone">
          <div data-platform="vacancy" className="square" />
        </div>

        <div className="platform-popup">
          <div className="triangle triangle--right" />
          <div className="text">{t('About.vacancy_popup')}</div>
          <span>
            <SmartLink href="/vakansii">{t('About.vacancy_link')}</SmartLink> →
          </span>
        </div>
      </div>
    );
  }

  platformKitchen() {
    const { t } = this.props;
    return (
      <div
        data-timer="1"
        data-in="28, 39"
        data-out="35, 31"
        className={this.getPlatformClass('kitchen')}
      >
        <DynamicLocaleImage platformName="Kitchen" />

        <div id="kitchen-in" className="platform-in" />
        <div id="kitchen-out" className="platform-out" />
        <div className="hero_move hero_move_animation">
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            className="preload"
            src={KitchenHeroRun00}
            alt=""
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={KitchenHeroRun10}
            alt=""
          />
          <div className="sprite" />
        </div>
        <div className="hero">
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            className="preload show_on_mobile"
            src={KitchenHero10}
            alt=""
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={KitchenHero00}
            alt=""
          />
          <div className="sprite" />
        </div>
        <div className="chief">
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            className="preload"
            src={KitchenChief00}
            alt=""
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={KitchenChief10}
            alt=""
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={KitchenChief20}
            alt=""
          />
          <div className="sprite" />
        </div>

        <div className="activeZone">
          <div data-platform="kitchen" className="square" />
        </div>

        <div className="platform-popup">
          <div className="triangle triangle--top" />
          <div className="text">{t('About.kitchenPopup')}</div>
          <span>
            <SmartLink href="/menu">{t('About.kitchen_link')}</SmartLink> →
          </span>
        </div>
      </div>
    );
  }

  platformActions() {
    const { t } = this.props;
    return (
      <div
        data-timer="1"
        data-in="19, 18"
        data-out="19, 18"
        className={`${this.getPlatformClass('actions')} allways-top`}
      >
        <DynamicLocaleImage platformName="Actions" />
        <div id="actions-in" className="platform-in" />
        <div id="actions-out" className="platform-out" />
        <img
          itemScope
          itemType="https://schema.org/ImageObject"
          src={ActionsParashute1}
          alt=""
          className="parachute_1"
        />
        <img
          itemScope
          itemType="https://schema.org/ImageObject"
          src={ActionsParashute3}
          alt=""
          className="parachute_3"
        />
        <img
          itemScope
          itemType="https://schema.org/ImageObject"
          src={ActionsParashute2}
          alt=""
          className="parachute_2"
        />

        <div className="giftman">
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={ActionsGiftMan00}
            alt="ActionsGiftMan00"
            className="preload"
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={ActionsGiftMan10}
            alt="ActionsGiftMan10"
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={ActionsGiftMan20}
            alt="ActionsGiftMan20"
          />
          <div className="sprite" />
        </div>

        <div className="hero">
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={ActionsHero00}
            alt="ActionsHero00"
            className="show_on_mobile preload"
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={ActionsHero10}
            alt="ActionsHero10"
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={ActionsHero20}
            alt="ActionsHero20"
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={ActionsHero30}
            alt="ActionsHero30"
          />
          <div className="sprite" />
        </div>

        <div className="activeZone">
          <div data-platform="actions" className="square" />
        </div>

        <div className="platform-popup">
          <div className="triangle triangle--left" />
          <div className="text">{t('About.actionsPopup')}</div>
          <span>
            <SmartLink href="/akcii">{t('About.actions_link')}</SmartLink> →
          </span>
        </div>
      </div>
    );
  }

  platformShops() {
    const { t } = this.props;
    return (
      <div
        data-timer="2"
        data-in="69, 46"
        data-out="61, 38"
        className={this.getPlatformClass('shops')}
      >
        <DynamicLocaleImage platformName="Shops" />

        <div className="shop" />
        <div className="red hide_on_mobile" />
        <div className="blue hide_on_mobile" />

        <div id="shops-in" className="platform-in" />
        <div id="shops-out" className="platform-out" />
        <div className="marker_container marker_container_1">
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={ShopsMarker}
            alt="ShopsMarker"
          />
        </div>
        <div className="marker_container marker_container_2">
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={ShopsMarker}
            alt="ShopsMarker"
          />
        </div>
        <div className="marker_container marker_container_3">
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={ShopsMarker}
            alt="ShopsMarker"
          />
        </div>
        <div className="marker_container marker_container_4">
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={ShopsMarker}
            alt="ShopsMarker"
          />
        </div>
        <div className="marker_container marker_container_5">
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={ShopsMarker}
            alt="ShopsMarker"
          />
        </div>

        <div className="hero_run hero_move_animation">
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={ShopsHeroRun00}
            alt="ShopsHeroRun00"
            className="preload"
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={ShopsHeroRun10}
            alt="ShopsHeroRun10"
          />
          <div className="sprite" />
        </div>

        <div className="hero">
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={ShopsHero00}
            alt="ShopsHero00"
            className="preload show_on_mobile"
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={ShopsHero10}
            alt="ShopsHero10"
          />
          <div className="sprite" />
        </div>

        <div className="activeZone">
          <div data-platform="shops" className="square" />
        </div>

        <div className="platform-popup">
          <div className="triangle triangle--left" />
          <div className="text">{t('About.shops_popup')}</div>
          <span>
            <SmartLink href="/addresses">{t('About.shops_link')}</SmartLink> →
          </span>
        </div>
      </div>
    );
  }

  platformGirl() {
    const { t } = this.props;
    return (
      <div
        data-timer="4"
        data-in="88, 17"
        data-out="88, 17"
        className={this.getPlatformClass('girl')}
      >
        <DynamicLocaleImage platformName="Girl" />
        <div id="girl-in" className="platform-in" />
        <div id="girl-out" className="platform-out" />
        <div className="hero">
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={GirlHero00}
            alt="GirlHero00"
            className="preload show_on_mobile"
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={GirlHero10}
            alt="GirlHero10"
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={GirlHero20}
            alt="GirlHero20"
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={GirlHero30}
            alt="GirlHero30"
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={GirlHero40}
            alt="GirlHero40"
          />

          <div className="sprite" />
        </div>

        <div className="cloud">
          <div className="sprite" />
        </div>

        <div className="activeZone">
          <div data-platform="girl" className="square" />
        </div>

        <div className="platform-popup">
          <div className="triangle triangle--bottom" />
          <div className="text">{t('About.girlPopup')}</div>
          <span>
            <SmartLink href="/addresses">{t('About.girl_link')}</SmartLink> →
          </span>
        </div>
      </div>
    );
  }

  initHeroOnBoard() {
    const left = Math.floor(window.innerWidth * 0.92);
    const bottom = Math.floor(window.innerHeight * 0.4);
    const width = Math.floor(window.innerWidth * 0.07);
    const {
      hero_on_board: { active, move, right },
    } = this.state;
    this.setState({
      hero_on_board: {
        position: {
          bottom,
          left,
        },
        width,
        active,
        move,
        right,
      },
    });
  }

  clickHandler(e) {
    let elements;
    if (document.msElementsFromPoint) {
      elements = document.msElementsFromPoint(e.pageX, e.pageY);
    } else {
      elements = document.elementsFromPoint(e.pageX, e.pageY);
    }
    const findedEl = Array.from(elements).find((el) =>
      el.classList.contains('square')
    );
    if (findedEl) {
      return this.setActive(findedEl.getAttribute('data-platform'));
    }
  }

  plarformNews() {
    const { t } = this.props;
    return (
      <div
        data-timer="2"
        data-in="65, 64"
        data-out="58, 57"
        className={this.getPlatformClass('news')}
      >
        <DynamicLocaleImage platformName="News" />
        <div id="news-in" className="platform-in" />
        <div id="news-out" className="platform-out" />
        <img
          itemScope
          itemType="https://schema.org/ImageObject"
          className="globus"
          src={NewsGlobus}
          alt=""
        />
        <img
          itemScope
          itemType="https://schema.org/ImageObject"
          src={NewsRuporShadow}
          alt=""
          className="rupor_shadow"
        />
        <img
          itemScope
          itemType="https://schema.org/ImageObject"
          src={NewsRupor}
          alt=""
          className="rupor"
        />
        <div className="hero_run hero_move_animation">
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={NewsHeroRun}
            alt=""
          />
        </div>
        <div className="hero_sit show_on_mobile">
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            className="show_on_mobile"
            src={NewsHeroSit1}
            alt=""
          />
          <img
            itemScope
            itemType="https://schema.org/ImageObject"
            src={NewsHeroSit2}
            alt=""
          />
        </div>

        <div className="activeZone">
          <div data-platform="news" className="square" />
        </div>

        <div className="platform-popup">
          <div className="triangle triangle--bottom" />
          <div className="text">{t('About.newsPopup')}</div>
          <span>
            <SmartLink href="/news">{t('About.news_link')}</SmartLink> →
          </span>
        </div>
      </div>
    );
  }

  animateAllwaysTopPlatforms(el, timer_class) {
    if (el.classList.contains('allways-top')) {
      el.classList.remove('allways-top');

      setTimeout(() => {
        el.classList.add('allways-top');
      }, (timer_class - 1) * 200 + 1000);
    }
  }

  render() {
    const { hero_on_board, speed, ready } = this.state;
    const { t } = this.props;
    return (
      <div className="about">
        <div className="platforms" onClick={(e) => this.clickHandler(e)}>
          <h1
            className="page-title show-on-mobile-only"
            dangerouslySetInnerHTML={{ __html: t('About.title') }}
          />
          <div className="platforms-container">
            <img
              className="platforms-container-img"
              src={overlayImage}
              alt="overlayImage"
            />
            {this.plarformZal()}
            {this.plarformPrizes()}
            {this.platformKitchen()}
            {this.platformActions()}
            {this.platformShops()}
            {this.platformGirl()}
            {this.plarformNews()}
            {this.platformVacancy()}
            <CustomText className="custom-text-wrap" />
          </div>
        </div>

        <div
          className={['overlay', ready ? 'overlay--hidden' : ''].join(' ')}
          style={{
            zIndex: 11,
            position: 'fixed',
            top: '0px',
            left: '0px',
            background: '#fff',
            display: 'block',
            width: '100vw',
            height: '100vh',
          }}
        >
          <div className="preloader">
            <span className="preloader__one" />
            <span className="preloader__two" />
            <span className="preloader__three" />
          </div>
        </div>

        <div
          className={`hero_on_board ${hero_on_board.active ? 'active ' : ''}${
            hero_on_board.right ? 'right ' : ''
          }${speed}${hero_on_board.no_animate ? 'no_animate ' : ''}`}
          style={{
            left: hero_on_board.position.left,
            bottom: hero_on_board.position.bottom,
            width: hero_on_board.width,
          }}
        >
          <img src={BoardHeroLeft10} alt="BoardHeroLeft10" />
        </div>
      </div>
    );
  }
}

export default withTranslation()(About);
