import cn from 'classnames';
import styles from './pageTitle.module.scss';

function PageTitle({
  header,
  className,
}: {
  header: string;
  className?: string;
}) {
  const style = className
    ? cn(styles.pageTitle, { [className]: className })
    : styles.pageTitle;

  return <h1 className={style}>{header}</h1>;
}

export default PageTitle;
