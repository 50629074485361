import './Main.scss';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import TrioOnMainPage from 'frontend/Components/Main/TrioOnMainPage/TrioOnMainPage';
import MainSlider from 'frontend/Components/Main/MainSlider/MainSlider';
import CustomText from 'frontend/Components/CustomText/CustomText';
import { PromoBlocks } from '../promo/ui/promo-blocks/promo-blocks';
import { Publication } from '../publication';

function Main(props) {
  const { t } = useTranslation();
  const renderMainSlider = () => {
    const { banner } = props;
    if (!banner || !banner.length) return null;
    const filtered = banner.filter((slide) => !!slide.image);
    const noAnim = filtered.length < 2;
    return (
      <div className={noAnim ? 'main-slider no-anim' : 'main-slider'}>
        <MainSlider t={t} banner={banner} />
      </div>
    );
  };

  const renderStocks = (positions = []) => {
    const { stocks } = props;
    return <TrioOnMainPage positions={positions} stocks={stocks} />;
  };

  const renderPromoBlocks = () => {
    const { promoLinks } = props;
    return <PromoBlocks promoLinks={promoLinks} />;
  };

  const renderNews = () => {
    const normalizeNews = props.news.map((item) => {
      return {
        ...item,
        publication_date: item.publication_from,
      };
    });
    return (
      <Publication
        titleText={t('Main.news')}
        bottomLinkText={t('Main.all_news')}
        href="news"
        list={normalizeNews}
      />
    );
  };

  const renderArticles = () => {
    return (
      <Publication
        href="articles"
        titleText={t('Main.articles')}
        bottomLinkText={t('Articles.allArticles')}
        list={props.articles}
      />
    );
  };

  const { mainpage } = props;
  const activeBlocks = mainpage.filter((block) => block.active);

  const activeBlocksKeys = {
    slider: renderMainSlider(mainpage),
    stocks: renderStocks(mainpage),
    promos: renderPromoBlocks(mainpage),
    news: renderNews(mainpage),
    articles: renderArticles(mainpage),
  };

  return (
    <div className="page-main">
      {activeBlocks.map(({ name }) => {
        if (!name) return null;
        return <Fragment key={name}>{activeBlocksKeys[name]}</Fragment>;
      })}

      <CustomText className="main-container main-container-inner custom-text-wrap" />
    </div>
  );
}

export default Main;
