import { useEffect, useState } from 'react';
import { setAvailable } from 'src/entities/complectation/model/slice';
import { useAppDispatch } from 'shared/lib/store';
import { useTranslation } from 'react-i18next';
import { getProducts } from 'src/entities/product/lib/useGetProducts';
import { IProduct } from 'types/product';
import ComplectationFree from './ComplectationFree';
import ComplectationPaid from './ComplectationPaid';
import styles from './complectation.module.scss';
import type { IComplectationRoot } from '../types';
import { isThereFree } from '../lib';

function ComplectationRoot({ isExact, available }: IComplectationRoot) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [products, setProducts] = useState<IProduct[]>([]);

  useEffect(() => {
    if (!available?.length) return;
    const ids = available.map(({ id }) => id);
    getProducts(ids).then((prods) => {
      setProducts(prods);
      const filtered = available.filter((item) =>
        prods.find(({ id }) => id === item.id)
      );
      dispatch(setAvailable(filtered));
    });
  }, [available, dispatch]);

  if (!available?.length) return null;

  const isFree = isThereFree(available);

  return (
    <>
      <div className={styles.titleText}>
        <span>
          {t(isFree ? 'Complectation.freeAndPaid' : 'Complectation.onlyPaid')}
        </span>
      </div>
      {isFree && <ComplectationFree isExact={isExact} products={products} />}
      <ComplectationPaid isExact={isExact} products={products} />
    </>
  );
}

export default ComplectationRoot;
