import { IProduct } from 'types/product';
import {
  complectationSliceSelectors,
  deleteAllPaid,
} from 'src/entities/complectation/model/slice';
import { useAppDispatch, useAppSelector } from 'shared/lib/store';
import { useTranslation } from 'react-i18next';
import CloseButton from 'src/shared/ui/close-button/CloseButton';
import { useHistory } from 'react-router';
import { route } from 'modules/route';
import { useCurrency } from 'src/entities/countries';
import styles from './complectation.module.scss';
import useDebouncer from '../lib/useDebouncer';
import { isThereFree } from '../lib';
import ComplectationItem from './complectation-item/ComplectationItem';
import useShowPaid from '../lib/useShowPaid';

interface IComplectationPaid {
  products: IProduct[];
  isExact: boolean;
}

function SummPrice({ qty, price }: { qty: number; price: number }) {
  const currency = useCurrency();
  const multiplier = qty || 1;
  return (
    <div className={styles.summContainer}>
      {price * multiplier}&nbsp;{currency}
    </div>
  );
}

function ComplectationPaid({ isExact, products }: IComplectationPaid) {
  const paid = useAppSelector(complectationSliceSelectors.selectPaid);
  const availiable = useAppSelector(
    complectationSliceSelectors.selectAvailable
  );

  const history = useHistory();

  const noFree = !!availiable.length && !isThereFree(availiable);

  const { t } = useTranslation();

  const { show, closeClick, wantMoreClick } = useShowPaid({
    noFree,
    paidItemsCount: Object.keys(paid).length,
    isExact,
  });

  const dispatch = useAppDispatch();
  const { toSend, addToSend } = useDebouncer();

  const deleteAll = async () => {
    dispatch(deleteAllPaid());
  };

  const onClick = async (id: number, qty: number) => {
    addToSend(id, qty);
  };

  if (!show)
    return (
      <div className={styles.moreButtonContainer}>
        <button
          type="button"
          className={styles.moreButton}
          onClick={() => {
            wantMoreClick();
            if (!isExact) {
              history.replace(route('cart'));
            }
          }}
        >
          {t('Complectation.needMore')}
        </button>
      </div>
    );

  return (
    <section className={styles.container}>
      {!noFree && (
        <div className={styles.colseButtonContainer}>
          <CloseButton
            className={styles.closeButton}
            onClick={() => {
              closeClick();
              deleteAll();
            }}
          />
        </div>
      )}
      {availiable.map(({ id }) => {
        if (!id) return null;
        const product = products.find(({ id: pId }) => id === pId);
        if (!product) return null;
        const currentQtyInReadux = paid[id]?.qty ?? 0;
        const currentToSend = toSend[id]?.qty ?? 0;
        const totalQty = currentQtyInReadux + currentToSend;
        return (
          <ComplectationItem
            key={id}
            product={product}
            qty={totalQty}
            isExact={isExact}
            summPrice={<SummPrice price={product.price} qty={totalQty} />}
            moreAvaliable
            setQty={onClick}
          />
        );
      })}
    </section>
  );
}

export default ComplectationPaid;
