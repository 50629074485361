import { ReactNode } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { SideBarContextProvider } from 'frontend/widgets/sideBar';
import {
  complectationInfoModal,
  forgetComplectationModal,
} from 'features/comlectation-info';
import { modalIds } from 'shared/config/modal-ids';
import loadable from '@loadable/component';

const ComplectationInfoModal = loadable(
  () =>
    import('features/comlectation-info').then(
      ({ ComplectationInfoModal: Modal }) => Modal
    ),
  { ssr: false }
);

const ForgetComplectationModal = loadable(
  () =>
    import('features/comlectation-info').then(
      ({ ForgetComplectationModal: Modal }) => Modal
    ),
  { ssr: false }
);

const ConfirmCityModal = loadable(
  () =>
    import('features/geopositioning').then(
      ({ ConfirmCityModal: Modal }) => Modal
    ),
  { ssr: false }
);

const SelectCityModal = loadable(
  () =>
    import('features/geopositioning').then(
      ({ SelectCityModal: Modal }) => Modal
    ),
  { ssr: false }
);

const registar = () => {
  NiceModal.register(
    complectationInfoModal,
    NiceModal.create(ComplectationInfoModal)
  );
  NiceModal.register(
    forgetComplectationModal,
    NiceModal.create(ForgetComplectationModal)
  );
  NiceModal.register(
    modalIds.ConfirmCityModalId,
    NiceModal.create(ConfirmCityModal)
  );

  NiceModal.register(
    modalIds.SelectCityModalId,
    NiceModal.create(SelectCityModal)
  );
};

export function ModalProvider({ children }: { children: ReactNode }) {
  registar();

  return (
    <NiceModal.Provider>
      <SideBarContextProvider>{children}</SideBarContextProvider>
    </NiceModal.Provider>
  );
}
