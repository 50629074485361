import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import WokSauceImage from 'assets/images/wok-box/wok_sauce.png';
import vegetablesImage from 'assets/images/wok-box/vegetables.unmin.jpg';
import DynamicImageWokBox from 'frontend/Components/DynamicImageWokBox';
import { useSelectedCountry } from 'src/entities/countries';
import cn from 'classnames';
import NoImgRadioButton from './NoImgRadioButton';
import ToppingOptionButton from './ToppingOptionButton';
import ConstructorWidget from '../../ConstructorWidget/ConstructorWidget';

function PlusIcon() {
  return (
    <div className="card-wok__icon-container">
      <i className="card-wok__icon">+</i>
    </div>
  );
}

function Bases({ children, t }) {
  return (
    <div className="card-wok__item-wrapper">
      <div className="card-wok__item item-options-img">
        <div className="card-wok__name-container">
          <p className="card-wok__name">{t('CardWok.choose_basis')}</p>
        </div>
        <ul className="card-wok__list">{children}</ul>
      </div>
    </div>
  );
}

function BaseRadioButton({ base, selected, onChange, t }) {
  const { isUkraine } = useSelectedCountry();

  return (
    <li className="card-wok__option">
      <div className="card-wok__input-wrapper">
        <input
          id={base.id}
          name={base.type}
          type="radio"
          value={base.id}
          checked={selected}
          onChange={onChange}
          className={cn('sw-radio', 'card-wok__radio', { ua: isUkraine })}
        />
        <label htmlFor={base.id} className="card-wok__label">
          {base.title}
        </label>
      </div>
      <div className="card-wok__img-option-container">
        <DynamicImageWokBox
          urlChunk={base.text_id}
          className="card-wok__img-option"
          alt={t('CardWok.wok_ingredients')}
        />
      </div>
    </li>
  );
}

function Vegetables({ children, t }) {
  return (
    <div className="card-wok__item-wrapper">
      <div className="card-wok__item vegetables">
        <div className="card-wok__name-container">
          <p className="card-wok__name">{t('CardWok.vegetables')}</p>
        </div>
        {children[0]}
        <div className="card-wok__text-container">
          <p className="card-wok__text">{t('CardWok.ingredients')}</p>
        </div>
        <div className="card-wok__img-container large">
          <img
            className="card-wok__vegetables-image"
            src={vegetablesImage}
            alt={t('CardWok.vegetables')}
          />
        </div>
      </div>
      {children[1]}
    </div>
  );
}

function Sauces({ children, t }) {
  return (
    <div className="card-wok__item-wrapper">
      <div className="card-wok__item">
        <div className="card-wok__name-container">
          <p className="card-wok__name">{t('CardWok.choose_sauce')}</p>
        </div>
        <ul className="card-wok__list">{children}</ul>
        <div className="card-wok__img-container">
          <img
            className="card-soup__image-width-100"
            src={WokSauceImage}
            alt={t('CardWok.wok_sauce')}
          />
        </div>
      </div>
    </div>
  );
}

function BuyButton({ onClickBuy, t }) {
  const { isUkraine } = useSelectedCountry();
  return (
    <div className="card__buy-action">
      <button
        type="button"
        className={cn('card__button', { ua: isUkraine })}
        onClick={onClickBuy}
      >
        {t('localization.Add to card')} <span className="card__button-add" />
      </button>
    </div>
  );
}

function TotalPrice({ price, currency, t }) {
  return (
    <div className="card-wok__total-container">
      <span className="card-wok__total-text">
        {`${t('CardWok.Total_sum')}: ${price} ${currency}`}
      </span>
    </div>
  );
}

function ToppingsAdditionals({ type, children, t }) {
  return (
    <div className="card-wok__item-wrapper additional">
      <div className="card-wok__item">
        <div className="card-wok__name-container">
          <p className="card-wok__name">{t(`CardWok.${[type]}`)}</p>
        </div>
        <ul className="card-wok__list">{children}</ul>
        <div className="card-wok__img-container">
          <DynamicImageWokBox
            className="card-soup__image-width-100"
            urlChunk={type}
            alt={t('CardWok.wok_ingredients')}
          />
        </div>
      </div>
    </div>
  );
}

class WokConstructorDesktop extends Component {
  render() {
    const {
      currency,
      constructor,
      onSelectBase,
      onSelectSauce,
      onSelectVegetables,
      onChangeNumberOfToppings,
      onToggleTopping,
      onAddToCart,
      t,
    } = this.props;

    const ToppingsCheckboxes = ({
      constructor,
      onChangeCheckboxHandler,
      onSelectHandler,
      currency,
      t,
    }) =>
      constructor.collectToppingsDividedByGroups(
        ([groupName, toppingsFromGroup]) => (
          <ToppingsAdditionals
            type={groupName}
            key={`topping-${groupName}`}
            t={t}
          >
            {toppingsFromGroup.map((topping) => (
              <ToppingOptionButton
                toppingSelected={constructor.isToppingSelected(topping)}
                disabled={constructor.canNotAddNewToppingsFor(groupName)}
                name={`toppings-${groupName}-${topping.id}`}
                onChangeCheckboxHandler={onChangeCheckboxHandler}
                topping={topping}
                maxAvailableCount={constructor.maxNumberOfTopping(topping)}
                onSelectHandler={onSelectHandler}
                currentCount={constructor.numberOfTopping(topping)}
                currency={currency}
                key={`toppings-${groupName}-${topping.id}`}
              />
            ))}
          </ToppingsAdditionals>
        )
      );

    return (
      <div className="card-wok__constructer">
        <h2 className="page-title">{t('CardWok.assemble_wok')}</h2>
        <div className="card-wok__wrapper">
          <section className="card-wok">
            <div className="card-wok__top">
              <div className="card-wok__container">
                <Bases t={t}>
                  {constructor.collectBases((base) => (
                    <BaseRadioButton
                      t={t}
                      base={base}
                      selected={constructor.isBaseSelected(base)}
                      onChange={onSelectBase}
                      key={base.id}
                    />
                  ))}
                </Bases>
                <PlusIcon />
                <Vegetables t={t}>
                  {constructor.collectVegetables
                    ? constructor.collectVegetables(
                        (vegetables, index, arr) => {
                          if (arr.length < 2) return;
                          return (
                            <NoImgRadioButton
                              item={vegetables}
                              selected={constructor.isVegSelected(vegetables)}
                              onChange={onSelectVegetables}
                              key={vegetables.id}
                            />
                          );
                        }
                      )
                    : undefined}
                  <TotalPrice
                    t={t}
                    price={constructor.totalPrice}
                    currency={currency}
                  />
                </Vegetables>
                <PlusIcon />
                <Sauces t={t}>
                  {constructor.collectSauces((sauce) => (
                    <NoImgRadioButton
                      item={sauce}
                      selected={constructor.isSauceSelected(sauce)}
                      onChange={onSelectSauce}
                      key={sauce.id}
                    />
                  ))}
                </Sauces>
              </div>
            </div>
            <div className="card-wok__additional">
              <div className="card-wok__title-container">
                <h2 className="card-wok__title">{t('CardWok.additional')}</h2>
              </div>
              <div className="card-wok__container last">
                {ToppingsCheckboxes({
                  constructor,
                  currency,
                  onSelectHandler: onChangeNumberOfToppings,
                  onChangeCheckboxHandler: onToggleTopping,
                  t,
                })}
              </div>
            </div>
          </section>
          <ConstructorWidget
            type="wok"
            constructor={constructor}
            currency={currency}
            button={<BuyButton t={t} onClickBuy={onAddToCart} />}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(WokConstructorDesktop);
