import { Component } from 'react';
import * as session from 'modules/session';
import { route } from 'modules/route';
import globalStore from 'modules/global-store';
import ssr from 'modules/decorators/ssr';
import api from 'modules/helpers/api';
import ErrorComponent from '../../Components/ErrorComponent/ErrorComponent';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs';
import CustomHelmet from '../../Components/CustomHelmet';
import styles from './staticPage.module.scss';

class StaticPage extends Component {
  static LOADED_KEY = 'static_page_loaded';

  static initialData = (fetch, params = {}, globalStore) => {
    const { static_id: text_id } = params;
    return fetch('static_page', { text_id })
      .then((result) => {
        if (!result) {
          globalStore.set('is404', true);
        }
        globalStore.set('static_page', result);
      })
      .catch((e) => {
        console.error('StaticPage', e);
        return '';
      });
  };

  constructor(props) {
    super(props);

    this.state = {
      page: globalStore.get('static_page'),
    };
  }

  componentDidMount() {
    if (globalStore.get(StaticPage.LOADED_KEY)) {
      globalStore.unlink(StaticPage.LOADED_KEY);
    } else {
      StaticPage.initialData(api, this.props.match.params, globalStore).then(
        () => {
          this.setState({
            page: globalStore.get('static_page'),
          });
        }
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.static_id === this.props.match.params.static_id
    ) {
      return false;
    }
    StaticPage.initialData(api, this.props.match.params, globalStore).then(
      () => {
        this.setState({
          page: globalStore.get('static_page'),
        });
      }
    );
  }

  render() {
    const { page } = this.state;
    const { t } = this.props;

    if (!page) {
      return <div />;
    }

    if (page && page.length === 0) {
      return <ErrorComponent />;
    }

    const { title, description } = page[0];

    const links = [
      {
        href: route('/'),
        text: t('localization.Main'),
      },
      {
        text: title,
      },
    ];

    return (
      <div className="page-container">
        <CustomHelmet title={title} />
        <div className="page-info">
          <div className="inner-part-B">
            {!session.get('isMobile', false) && <Breadcrumbs links={links} />}
            {page ? (
              <div>
                <h1
                  className={session.get('isMobile', false) ? 'page-title' : ''}
                >
                  {title}
                </h1>
                <div
                  className={styles.mainContainer}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </div>
            ) : undefined}
          </div>
        </div>
      </div>
    );
  }
}

export default ssr(StaticPage);
