import { useState, useEffect } from 'react';
import moment from 'moment';
import cx from 'classnames';
import style from './TimeBadge.module.scss';

function TimeBadge(props) {
  const { badge: { type, action_from, action_to } = {}, t } = props || {};
  const [timer = '', setTimer] = useState();
  const [timerType = '', setType] = useState();

  let interval;

  const from = moment(action_from);
  const to = moment(action_to);

  function getDiff(a, b) {
    return moment.duration(a.diff(b));
  }

  function getPeriod(from, to) {
    return `${from.format('HH:mm')}-${to.format('HH:mm')}`;
  }

  function pretifyTime(time, format) {
    setType(format);
    switch (format) {
      case 'days':
        return Math.trunc(time.asDays());
      case 'hours':
        return moment(
          `${time.get('hours')}:${time.get('minutes')}:${time.get('seconds')}`,
          'HH:mm:ss'
        ).format('HH:mm:ss');
      default:
    }
  }

  function initTime() {
    if (type === 1) {
      if (from < to) {
        setTimer(getPeriod(from, to));
      }
    } else if (type === 2) {
      interval = setInterval(() => {
        const diff = getDiff(to, moment());
        const format = diff.get('days') < 1 ? 'hours' : 'days';
        if (diff.asMilliseconds() <= 0) {
          clearInterval(interval);
        }
        setTimer(pretifyTime(diff, format));
      }, 1000);
    }
  }

  useEffect(() => {
    initTime();
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);
  if (!type || !action_from || !action_to) {
    return null;
  }

  function renderPeriod() {
    if (!timer) {
      return null;
    }
    return (
      <div className={cx(style.badge, style.period)}>
        {`${t('Card.badge_only')} ${timer}`}
      </div>
    );
  }

  function renderTimer() {
    if (!timer) {
      return null;
    }
    return (
      <div className={cx(style.badge, style.timer)}>
        {`${t('Card.badge_offer')} ${timer} ${
          timerType === 'days' ? t('Card.badge_days') : ''
        }`}
      </div>
    );
  }

  switch (type) {
    case 1:
      return renderPeriod();
    case 2:
      return renderTimer();
    default:
      return null;
  }
}
export default TimeBadge;
