import EventEmitter from 'wolfy87-eventemitter';

export const cardsEmitter = new EventEmitter();
export const deliveryCheckEmitter = new EventEmitter();
export const wsEmitter = new EventEmitter();
export const userEmitter = new EventEmitter();
export const userProfileEmitter = new EventEmitter();
export const reviewsFilterEmitter = new EventEmitter();
export const modalEmitter = new EventEmitter();
export const yandexEmitter = new EventEmitter();
export const vacanciesEmitter = new EventEmitter();
export const promocodeEmitter = new EventEmitter();
export const toggleFilter = new EventEmitter();
export const eventsFilterEmitter = new EventEmitter();
export const ProductList = new EventEmitter();
export const orderEmitter = new EventEmitter();
export const searchEmitter = new EventEmitter();
export const mobileMenuEmitter = new EventEmitter();
export const discountPhone = new EventEmitter();
export const nearestStoreEmitter = new EventEmitter();
export const filterPanelEmitter = new EventEmitter();
export const seoEmitter = new EventEmitter();
export const historyEmitter = new EventEmitter();
export const eventBus = new EventEmitter();
export const recommendedEmitter = new EventEmitter();
