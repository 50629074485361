import { useEffect, useState } from 'react';
import { route } from 'modules/route';
import './HalloweenActionModal.scss';

const SECRET_CODE = 'МЕТЛА';
const code = ['', '', '', '', ''];

function DigitOverlay(props) {
  const [value, setValue] = useState('');

  const getRand = (min, max) => {
    const rand = Math.floor(Math.random() * max) + min;
    const sign = Math.round(Math.random()) * 2 - 1;

    return rand * sign;
  };

  const [position, setPosition] = useState({
    left: getRand(0, 150),
    top: getRand(0, 150),
  });

  useEffect(() => {
    if (props.value !== '') {
      setPosition({ left: 0, top: 0 });
      setValue(props.value);
    } else {
      setValue('🦇');
      setPosition({ left: getRand(0, 150), top: getRand(0, 150) });
    }
  }, [props.value]);

  const getStyles = () => {
    const styles = {
      fontSize: '20em',
      left: `${position.left}px`,
      opacity: 0,
      top: `${position.top}px`,
    };

    if (props.value !== '') {
      styles.fontSize = '2.5em';
      if (props.value === '🎃' || props.value === '👻') {
        styles.fontSize = '2em';
      }
      styles.opacity = 1;
    }

    return styles;
  };

  return (
    <h1 className="digit-overlay" style={getStyles()}>
      {value}
    </h1>
  );
}

function Digit({ index, initialValue }) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const focus = (index) => {
    document.getElementById(`digit-input-${index}`).focus();
  };

  const focusNext = () => {
    const nextIndex = Math.min(index + 1, SECRET_CODE.length - 1);
    focus(nextIndex);
  };

  const focusPrev = () => {
    const prevIndex = Math.max(0, index - 1);
    focus(prevIndex);
  };

  const handleOnChange = (e) => {
    const newValue = e.target.value.slice(-1).toUpperCase();
    if (newValue.match(/[А-Я]/i)) {
      setValue(newValue);
      if (!newValue && index >= 0) {
        focusPrev();
      } else if (index < SECRET_CODE.length) {
        focusNext();
      }
    }
  };

  const handleOnKeyDown = (e) => {
    if ((e.key === 'Backspace' || e.key === 'Delete') && index >= 0) {
      e.preventDefault();
      setValue('');
      focusPrev();
    }
    if (e.key === 'ArrowLeft' && index >= 0) {
      focusPrev();
    }
    if (e.key === 'ArrowRight' && index >= 0) {
      focusNext();
    }
  };

  const id = `digit-input-${index}`;

  return (
    <div className="digit">
      <input
        id={id}
        type="text"
        value={value}
        onChange={handleOnChange}
        onKeyDown={handleOnKeyDown}
        autoComplete="off"
        spellCheck="false"
      />
      <div className="underline" />
      <DigitOverlay value={value} />
    </div>
  );
}

function HalloweenActionModal() {
  const [isShowErrorMessage, setIsShowErrorMessage] = useState(false);
  const [initialValue, setInitialValue] = useState('');
  const checkCode = () => {
    let currentCode = '';
    for (let i = 0; i < SECRET_CODE.length; i += 1) {
      currentCode += document.getElementById(`digit-input-${i}`).value;
    }
    if (currentCode === SECRET_CODE) {
      window.location.replace(route('game/wheelOfFortune'));
    } else {
      setIsShowErrorMessage(true);
      const errorValue = initialValue === '🎃' ? '👻' : '🎃';
      setInitialValue(errorValue);
    }
  };

  return (
    <div className="promocode-modal">
      <div className="main-cart-modal__title-container title-container-font-size">
        <p>Сыграй, если осмелишься!</p>
        <div className="main-cart-modal__subtitle-container sub-container mobile-font-size">
          <p>На сайте мы спрятали пасхалки.</p>
          <p>Найди их, собери слово из пяти букв и открой секретную страницу</p>
        </div>
      </div>
      <div className="input-code-wrapp">
        <div className="input-code">
          {code.map((el, i) => {
            return <Digit key={i} index={i} initialValue={initialValue} />;
          })}
        </div>
      </div>
      {isShowErrorMessage && (
        <div className="error-message-wrapp">
          <p className="margin-bottom-0">НЕВЕРНО. ПОПРОБУЙ ЕЩЕ РАЗ!</p>
        </div>
      )}
      <div className="main-cart-modal__btn-container margin-top-20">
        <button type="button" className="sw-button" onClick={checkCode}>
          Играть!
        </button>
      </div>
    </div>
  );
}

export default HalloweenActionModal;
