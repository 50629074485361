import { useTranslation } from 'react-i18next';
import { Suggest } from 'features/address-suggest';
import { DeliveryMap } from 'features/delivery-map';
import styles from './suggestionField.module.scss';

function SuggestionField({ field }: { field: any }) {
  const { t } = useTranslation();

  const { name, required, fieldsetClassName, invalid, value = '' } = field;

  return (
    <div
      className={`input-row ${fieldsetClassName || ''} ${
        invalid ? ' invalid' : ''
      }`}
    >
      <label htmlFor={`field-${name}`} className={required ? 'required' : ''}>
        {t('locale.address')}
      </label>

      <Suggest
        name={name}
        required={required}
        placeholder={t(`locale.Enter_address`)}
        initialValue={value}
      />
      <label htmlFor={`field-${name}`} className="bottom-label desktop">
        {t('locale.If_you_do_not_found_address')}
      </label>
      <div className={styles.mapContainer}>
        <DeliveryMap />
      </div>
    </div>
  );
}

export default SuggestionField;
