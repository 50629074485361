import { useTranslation } from 'react-i18next';
import SuccessResult from '../../SuccessResult/SuccessResult';
import style from './SuccessResultModal.module.scss';
import Emitter from '../../NewCartOrder/Emitters';

function SuccessResultModal() {
  const { t } = useTranslation();
  const closeModal = () => {
    Emitter.emit('MODAL_CLOSE');
  };

  return (
    <div className={style.successResultModal}>
      <button
        type="button"
        onClick={closeModal}
        className="close-modal-btn"
        aria-label="Close"
      />
      <SuccessResult text={t('QualityPage.review_important')} />
    </div>
  );
}
export default SuccessResultModal;
