import './QualityPage.scss';
import { Component } from 'react';
import { Helmet } from 'react-helmet';
import { route } from 'modules/route';
import { withTranslation } from 'react-i18next';
import { LinkButton } from 'shared/ui/link-button';
import Quality from '../../Components/Quality/Quality';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs';
import SuccessResult from '../../Components/SuccessResult/SuccessResult';

class QualityPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSended: false,
    };
  }

  onQualitySuccess = () => {
    this.setState({
      isSended: true,
    });
  };

  render() {
    const { t } = this.props;
    const { isSended } = this.state;

    const links = [
      {
        href: route('/'),
        text: t('localization.Main'),
      },
      {
        text: t('Quality.quality'),
      },
    ];
    return (
      <div className="page-container">
        <div className="page-quality__breadcrumbs">
          <Breadcrumbs links={links} />
        </div>
        <div className="page-quality">
          <div className="page-inner">
            <Helmet title={t('Quality.quality')} />
            <div className="inner-part-B">
              {!isSended ? (
                <Quality onSuccess={this.onQualitySuccess} />
              ) : (
                <div>
                  <div className="page-quality__thanks">
                    <SuccessResult text={t('QualityPage.review_important')} />
                  </div>
                  <LinkButton href="/">{t('QualityPage.to_main')}</LinkButton>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(QualityPage);
