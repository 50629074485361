import { useEffect, useState } from 'react';
import api from 'modules/helpers/api';
import { userEmitter } from 'modules/event-emitters';
import { LinkTabs } from 'shared/ui/link-tabs';
import OrdersComponent from 'src/frontend/Components/Profile/Orders/Orders';
import { IProduct } from 'types/product';
import { IOrder } from 'frontend/Pages/Profile/lib/IOrder';
import { useTranslation } from 'react-i18next';

function Orders() {
  const { t } = useTranslation();
  const tabsItems = [
    {
      url: `/profile`,
      title: t('Menu.profile'),
    },
    {
      url: `/profile/orders`,
      title: t('Menu.my_orders'),
    },
    {
      url: `/profile/favorites`,
      title: t('Menu.favorites'),
    },
  ];
  const [state, setState] = useState<{ orders: IOrder[]; loaded: boolean }>({
    orders: [],
    loaded: false,
  });

  const getOrders = () => {
    api('user.orders')
      .then((orders = []) => {
        const ids: number[] = [];
        orders.forEach((order: IOrder) => {
          const { items = [] } = order;
          items.forEach((item) => {
            if (ids.indexOf(+item.product_id) === -1) {
              ids.push(item.product_id);
            }
          });
        });
        return { ids, orders };
      })
      .then(({ ids = [], orders = [] }) => {
        if (!ids || !ids.length) {
          setState({
            orders,
            loaded: true,
          });
          return;
        }
        api('product.get', { id: ids }).then((products: IProduct[] = []) => {
          orders = orders.map((order: IOrder) => {
            const { items = [] } = order;
            items.map((item) => {
              const product = products.find(
                (prod) => +prod.id === +item.product_id
              );
              if (product) {
                item.product = product;
                return item;
              }
            });
            return order;
          });
          setState({
            orders,
            loaded: true,
          });
        });
      })
      .catch(() => {
        setState({
          orders: [],
          loaded: true,
        });
      });
  };

  const handlerOrder = () => {
    setState({
      orders: [],
      loaded: false,
    });
  };

  useEffect(() => {
    getOrders();
    userEmitter.addListener('Profile.MenuMobile.logout', handlerOrder);

    return () => {
      userEmitter.removeListener('Profile.MenuMobile.logout', handlerOrder);
    };
  }, []);

  const { loaded, orders } = state;
  return (
    <div className="profile__page">
      {!loaded ? (
        <div className="preloader">
          <span className="preloader__one" />
          <span className="preloader__two" />
          <span className="preloader__three" />
        </div>
      ) : undefined}
      <LinkTabs items={tabsItems} />
      <OrdersComponent orders={orders} />
    </div>
  );
}

export default Orders;
