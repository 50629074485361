import { Component } from 'react';
import Modal from 'react-modal';
import { modalEmitter } from 'modules/event-emitters';
import { withTranslation } from 'react-i18next';
import MobileImage from 'assets/images/icon/mobile.svg?url';
import AndroidMobileImage from 'assets/images/android.svg?url';
import { analytics } from 'frontend/analytics/analytics';
import modernModal from '../ModernModal';

const modalStyles = {
  overlay: {
    zIndex: 20,
    backgroundColor: 'rgba(252, 252, 252, 0.7',
    overflowY: 'auto',
  },
  content: {
    maxWidth: '300px',
    top: '15vw',
    left: '0',
    right: '0',
    bottom: 'auto',
    margin: 'auto',
    overflow: 'visible',
    padding: '24px',
    border: 'none',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 16px 60px 20px',
    boxSizing: 'border-box',
    height: '400px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
};

class MobileModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
    };
  }

  componentDidMount() {
    Modal.setAppElement('body');
    modalEmitter.addListener('Modal.MobileModal.Open', this.openModal);
  }

  componentWillUnmount() {
    modalEmitter.removeListener('Modal.MobileModal.Open', this.openModal);
  }

  openModal = () => {
    this.setState({
      modalIsOpen: true,
    });
    modernModal.openModal();
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
    modernModal.closeModal();
  };

  render() {
    const { t } = this.props;
    return (
      <Modal
        isOpen={this.state.modalIsOpen}
        style={modalStyles}
        onRequestClose={this.closeModal}
        contentLabel="Movie Modal"
      >
        <div className="main-mobile-modal">
          <a
            className="main-mobile-modal__img-link"
            target="_blank"
            href="https://itunes.apple.com/ru/app/%D1%81%D1%83%D1%88%D0%B8-wok/id673954325?mt=8"
            rel="noreferrer"
            onClick={() => analytics.appStoreAttention()}
          >
            <img
              itemType="https://schema.org/ImageObject"
              itemScope
              src={MobileImage}
              alt={t('MobileModal.mobile_phone_with_logo')}
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=ru.devcluster.sushiwok"
            target="_blank"
            rel="noreferrer"
            onClick={() => analytics.googlePlayAttention()}
            className="classToLink"
          >
            <img
              itemScope
              itemType="https://schema.org/ImageObject"
              src={AndroidMobileImage}
              alt={t('MobileModal.mobile_phone_with_logo')}
            />
          </a>
          <p>{t('MobileModal.mobile_application')}</p>
          <p>
            <a
              className="main-mobile-modal__link"
              href="https://itunes.apple.com/ru/app/%D1%81%D1%83%D1%88%D0%B8-wok/id673954325?mt=8"
              target="_blank"
              rel="noreferrer"
              onClick={() => analytics.appStoreAttention()}
            >
              Ios
            </a>
            &nbsp;{t('MobileModal.and')}&nbsp;
            <a
              className="main-mobile-modal__link"
              target="_blank"
              href="https://play.google.com/store/apps/details?id=ru.devcluster.sushiwok"
              rel="noreferrer"
              onClick={() => analytics.googlePlayAttention()}
            >
              Android
            </a>
          </p>
        </div>
        <div>
          <button
            type="button"
            onClick={this.closeModal}
            className="close-modal-btn"
          />
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(MobileModal);
