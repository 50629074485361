import { Component } from 'react';
import { route } from 'modules/route';
import ssr from 'modules/decorators/ssr';
import api from 'modules/helpers/api';
import globalStore from 'modules/global-store';
import cn from 'classnames';
import { Media } from 'shared/lib/media';
import { storeHelper } from 'src/entities/stores';
import Breadcrumbs from '../../../Components/Breadcrumbs/Breadcrumbs';
import ReviewsAddModal from '../../../Components/Reviews/AddModal/AddModal';
import StoresPageContent from './page-content/StoresPageContent';
import CustomText from '../../../Components/CustomText/CustomText';
import CustomHelmet from '../../../Components/CustomHelmet';
import styles from './stores.module.scss';

class Stores extends Component {
  static LOADED_KEY = 'stores_loaded';

  static initialData = (fetch, params, globalStore) => {
    return fetch('store.get_list_city')
      .then((stores) =>
        globalStore.set('stores', storeHelper.flatMetro(stores))
      )
      .catch((e) => {
        console.error('Stores', e);
        return [];
      });
  };

  constructor(props) {
    super(props);
    this.state = {
      stores: globalStore.get('stores', []),
    };
  }

  componentDidMount() {
    if (globalStore.get(Stores.LOADED_KEY)) {
      globalStore.unlink(Stores.LOADED_KEY);
    } else {
      Stores.initialData(api, {}, globalStore)
        .then(() => {
          this.setState({
            stores: globalStore.get('stores', []),
          });
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }

  getTitle() {
    const { city_name = '' } = globalStore.get('current_city');
    const { t } = this.props;
    return `${t('localization.Stores')} ${t(
      'localization.companyName'
    )} ${city_name}`;
  }

  render() {
    const { stores } = this.state;
    const { t } = this.props;

    const links = [
      {
        href: route('/'),
        text: t('localization.Main'),
      },
      {
        text: t('localization.Stores'),
      },
    ];

    return (
      <div className="page-container">
        <CustomHelmet title={this.getTitle()} />
        <div className={cn('page-stores', styles.pageStores)}>
          <div className="inner-part-B" style={{ minHeight: '1000px' }}>
            <Media greaterThan="laptop">
              <Breadcrumbs links={links} />
            </Media>
            <StoresPageContent stores={stores} />
            <CustomText
              className={cn('custom-text-wrap', styles.customTextWrap)}
            />
          </div>
        </div>
        <ReviewsAddModal city_stores={stores} />
      </div>
    );
  }
}

export default ssr(Stores);
