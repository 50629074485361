import { ExternalImage } from 'shared/ui/image';
import { useTranslation } from 'react-i18next';
import htmlParser from 'shared/lib/html-parser';
import SocialElements from 'frontend/Components/SocialElements/SocialElements';
import { useSelectCountryMainDomain } from 'src/entities/countries';
import { formatDateForAttribute, formatMonthWithLocale } from 'shared/lib/date';
import styles from './eventSingle.module.scss';

interface IEventSingle {
  content: {
    publication_from: string;
    publication_date: string;
    category_name?: string;
    description: string;
    title: string;
    name: string;
    images: { filename: string }[];
    announcement_text: string;
  };
}

export function EventSingle({ content }: IEventSingle) {
  const {
    publication_from,
    publication_date,
    category_name,
    description,
    title,
    name,
    images,
    announcement_text,
  } = content;

  const {
    i18n: { language },
  } = useTranslation();
  const mainDomain = useSelectCountryMainDomain();

  if (!content) return null;

  const imageReplacer = ({ alt, src }: { alt: string; src: string }) => {
    return (
      <ExternalImage
        width={600}
        height={400}
        mainDomain={mainDomain}
        alt={alt || name}
        src={src?.replace('/img', '')}
        className={styles.eventImage}
      />
    );
  };

  const parseDescription = (eventDescription: string) => {
    try {
      const parsed = htmlParser(eventDescription, {
        from: 'img',
        to: imageReplacer,
      });
      return parsed;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const renderCategory = () =>
    category_name && (
      <div className={styles.category} itemProp="genre">
        {category_name}
      </div>
    );

  const currentPublication = publication_date || publication_from;

  const dateString = formatMonthWithLocale(currentPublication, language);

  const imageFile = images?.[0]?.filename;
  const eventImage = imageFile && `/img/${imageFile}`;

  return (
    <>
      {renderCategory()}
      <div className={styles.root}>
        <div itemProp="description">{parseDescription(description)}</div>
        <div className={styles.actionBar}>
          <meta
            itemProp="datePublished"
            content={formatDateForAttribute(currentPublication)}
          />
          <div className={styles.date}>{dateString}</div>
          <meta itemProp="author" content="Суши Wok" />

          <SocialElements
            title={name || title}
            image={eventImage}
            description={announcement_text}
          />
        </div>
      </div>
    </>
  );
}
