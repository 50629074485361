import './Favorites.scss';
import './FavoritesMobile.scss';
import * as session from 'modules/session';
import { useTranslation } from 'react-i18next';
import useFavorites from 'frontend/data/user/favorites/useFavorites';
import Card from '../Products/Card/Card';

function Favorites() {
  const { t } = useTranslation();
  const { data: favorites } = useFavorites();

  const { items = [] } = session.get('cart');

  return (
    <div className="favorite__container">
      {favorites && favorites.length > 0 ? (
        <div className="card-list">
          <div className="card-list__content--grid">
            {favorites.map((product) => {
              const cart_item = items.find(
                (item: any) => item.id === product.id
              );
              return (
                <Card
                  key={product.id}
                  product={product}
                  view_type="grid"
                  position="fromFavorites"
                  cart_item={cart_item}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <div className="favorite__error">
          <p>
            {' '}
            {t('Favorites.press_stars')} <br /> {t('Favorites.in_menu')} ...
          </p>
          <p>... {t('Favorites.goods_will_appear')}</p>
        </div>
      )}
    </div>
  );
}

export default Favorites;
