/**
 * Created by rebu on 22.02.17.
 *
 * Package Components.Phone.GetCode.GetCode
 *
 * Usage: <GetCode {...params} />
 *     params:
 *          phone: phone to send code (default NULL)
 *          [tag: tag html element (default "button")]
 *          [className: class of html element (default "phone-send-code-button")]
 *          [value: text of element (default "Request code")]
 *          [repeatValue: text for repeat message, reqular pattern %time% will be replaced on time to repeat (default "Retry after %time%")]
 *
 */
import autobind from 'autobind-decorator';
import { withTranslation } from 'react-i18next';
import { userEmitter } from 'modules/event-emitters';
import api from 'modules/helpers/api';
import globalStore from 'modules/global-store';
import AbstractClass from '../Abstract/index';
import Emitter from '../../NewCartOrder/Emitters';

const CODES = {
  STOP_FLOOD: 403,
  CODE_ALREADY_SENT: 5,
};
class GetCode extends AbstractClass {
  className = 'phone-send-code-button';

  value = 'Request code';

  @autobind
  validateCode() {
    const {
      data: { phone },
    } = this.state;
    const { t } = this.props;
    const city = globalStore.get('current_city').text_id;
    api('/endpoint/phone/validate', {
      phone,
      city,
    })
      .then((response) => {
        if (response.code === CODES.CODE_ALREADY_SENT) {
          userEmitter.emit('User.CodeAlreadySent', response);
        }
        localStorage.setItem('processPhone', phone);
        userEmitter.emit('User.GetCode.Response', response);
      })
      .catch(({ data }) => {
        if (data.message && data.code !== CODES.STOP_FLOOD) {
          console.warn(`Error: ${data.message}`);
          Emitter.emit('MODAL_CALL', {
            modal: 'ErrorCartModal',
            value: t(`BlockTotal.${data.message}`),
          });
        } else {
          Emitter.emit('MODAL_CALL', {
            modal: 'ErrorCartModal',
            value: `${t('localization.Error!')} ${data.code}`,
          });
          console.warn(`Error: ${data.message}`);
        }
      });

    return true;
  }

  @autobind
  sendHandler() {
    const { checkedInfo, handleButtonPress } = this.props;
    handleButtonPress();
    if (checkedInfo !== undefined) {
      if (checkedInfo) {
        this.validateCode();
      }
    } else {
      this.validateCode();
    }
  }
}

export default withTranslation()(GetCode);
