import './Breadcrumbs.scss';
import { Component } from 'react';
import { Link } from 'react-router-dom';

class Breadcrumbs extends Component {
  render() {
    const { links } = this.props;
    const linkElements = links.map(({ href, text }, index, array) => {
      if (!text) return undefined;

      text = text.substr(0, 1).toUpperCase() + text.substr(1);

      return (
        <span
          className="breadcrumbs--item"
          key={`bc-${index}`}
          itemProp="itemListElement"
          itemScope
          itemType="http://schema.org/ListItem"
        >
          {href ? (
            <Link itemProp="item" to={href} className="breadcrumbs--item">
              <span itemProp="name">{text}</span>
            </Link>
          ) : (
            <span itemProp="name" className="breadcrumbs--item">
              {text}
            </span>
          )}
          <meta itemProp="position" content={index + 1} />
          {array.length - 1 > index ? (
            <span className="breadcrumbs--arrow">{'>>'}</span>
          ) : undefined}
        </span>
      );
    });

    return (
      <div
        itemScope
        itemType="http://schema.org/BreadcrumbList"
        className="breadcrumbs"
      >
        {linkElements}
      </div>
    );
  }
}

export default Breadcrumbs;
