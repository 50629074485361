import { Component } from 'react';
import globalStore from 'modules/global-store';
import { withBreakpoints } from 'frontend/hocs';
import { ExternalImage } from 'shared/ui/image';
import { connect } from 'react-redux';
import { SmartLink } from 'shared/ui/smart-link';
import style from './MainSlider.module.scss';

class MainSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewedSlideIndexes: [0, 1, 2],
      initialized: false,
    };
    this.initSlides();
  }

  componentDidMount() {
    this.initFlickity();
  }

  componentWillUnmount() {
    this.flickity.destroy();
  }

  onClickPrev = () => {
    const { viewedSlideIndexes } = this.state;

    const firstIndex = viewedSlideIndexes[0];
    let newIndex = this.slides.length - 1;
    if (firstIndex > 0) {
      newIndex = firstIndex - 1;
    }
    viewedSlideIndexes.unshift(newIndex);
    viewedSlideIndexes.pop();
    this.setState({
      viewedSlideIndexes,
    });
    this.flickity.previous();
  };

  onClickNext = () => {
    const { viewedSlideIndexes } = this.state;

    const lastIndex = viewedSlideIndexes[viewedSlideIndexes.length - 1];
    let newIndex = 0;
    if (lastIndex < this.slides.length - 1) {
      newIndex = lastIndex + 1;
    }
    viewedSlideIndexes.push(newIndex);
    viewedSlideIndexes.shift();
    this.setState({
      viewedSlideIndexes,
    });
    this.flickity.next();
  };

  getSlidePosition = (slideIndex) => {
    const { viewedSlideIndexes } = this.state;
    const findedIndex = viewedSlideIndexes.findIndex((viewedIndex) => {
      return viewedIndex === slideIndex;
    });
    const positions = ['left', 'center', 'right'];
    return positions[findedIndex] || 'top';
  };

  initFlickity = () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const Flickity = require('flickity');
    this.flickity = new Flickity(this.slider, {
      prevNextButtons: false,
      pageDots: false,
      setGallerySize: false,
      cellAlign: 'left',
      draggable: this.props.isMobile && !this.isOneSlide,
      wrapAround: true,
    });
    this.setState({
      initialized: true,
    });
  };

  initSlides() {
    const { banner, isMobile } = this.props;
    let filtered = banner.filter((slide) => !!slide.image);

    if (!isMobile && filtered.length > 1) {
      filtered.unshift(filtered.splice(-1, 1)[0]);
    }

    this.isOneSlide = filtered.length < 2;
    if (!this.isOneSlide) {
      filtered = filtered.concat(filtered);
    }
    this.slides = filtered;
  }

  renderSlide(slideIndex) {
    const { t, mainDomain } = this.props;
    const slide = this.slides[slideIndex];
    let url = null;
    let menu = null;

    if (slide.type && slide.url_param) {
      switch (slide.type) {
        case 'product':
          if (!slide.menu_id) {
            break;
          }
          menu = globalStore
            .get('menu', [])
            .find((item) => +item.id === +slide.menu_id);
          if (!menu) {
            break;
          }
          url = `menu/${menu.text_id}/${slide.url_param}`;
          break;
        case 'menu':
          url = `menu/${slide.url_param}`;
          break;
        case 'action':
          url = `akcii/${slide.url_param}`;
          break;
        default:
      }
    }
    const sliderPosition = this.getSlidePosition(slideIndex);
    return (
      <div
        className={
          this.isOneSlide
            ? `${style.sliderItem} ${style.noAnim}`
            : `${style.sliderItem} ${style[sliderPosition]}`
        }
        key={slideIndex}
      >
        <div
          className={
            this.isOneSlide
              ? `${style.imageContainer} ${style.noAnim}`
              : style.imageContainer
          }
        >
          <SmartLink href={`/${url}`}>
            <ExternalImage
              mainDomain={mainDomain}
              src={slide?.image}
              alt={t('Main.slide')}
              visibleByDefault
              width={650}
              height={650}
              sizes={{
                mobile: {
                  width: 410,
                  height: 410,
                },
                tablet: {
                  width: 410,
                  height: 410,
                },
                desktop: {
                  width: 650,
                  height: 650,
                },
              }}
            />
          </SmartLink>
        </div>
      </div>
    );
  }

  renderPreloadSlides = () => {
    const preloadSlides = [...this.slides].splice(0, 3);
    return preloadSlides.map((slide, index) => {
      return this.renderSlide(index);
    });
  };

  render() {
    const { initialized } = this.state;

    return (
      <div className={style.wrapper}>
        <div
          className={`${this.isOneSlide ? style.noAnim : ''} ${
            style.sliderContainer
          } ${initialized ? style.visible : style.hidden}`}
        >
          <div
            className={
              this.isOneSlide
                ? `${style.noAnim}`
                : `${style.sliderButton} ${style.prev}`
            }
            onClick={this.onClickPrev}
          />
          <div className={style.sliderContent} ref={(r) => (this.slider = r)}>
            {this.slides.map((slide, index) => {
              return this.renderSlide(index);
            })}
          </div>
          <div
            className={
              this.isOneSlide
                ? `${style.noAnim}`
                : `${style.sliderButton} ${style.next}`
            }
            onClick={this.onClickNext}
          />
        </div>
        <div
          className={`${style.sliderContainer} ${
            initialized ? style.hidden : style.visible
          }`}
        >
          <div className={`${style.sliderContent} ${style.preload}`}>
            {this.renderPreloadSlides()}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ countries }) => ({
  mainDomain: countries.country.main_domain,
});

export default connect(mapStateToProps)(withBreakpoints(MainSlider));
