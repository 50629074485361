import { Component } from 'react';
import cn from 'classnames';
import Scroll from 'react-scroll/modules';
import { withRouter } from 'react-router-dom';
import globalStore from 'modules/global-store';
import queryString from 'query-string';
import { analytics } from 'frontend/analytics/analytics';
import {
  getEcomDataToAnalytics,
  getFbPixelDataToCart,
} from 'frontend/analytics/analyticsHelper';
import { withBreakpoints } from 'frontend/hocs';

import { connect } from 'react-redux';
import { replaceQuery } from '../../../../modules/router-query-utils';
import Additional from './Additional';
import Emitter from '../../NewCartOrder/Emitters';
import style from './CardModal.module.scss';
import CardComponent from './CardComponent';

class CardModal extends Component {
  constructor(props) {
    super(props);
    const { value: { product } = {} } = props;
    const scrollTop = window.scrollY;
    const storeProduct = globalStore.get('product', {});
    const isSinglePage = !!Object.keys(storeProduct).length;
    const query = queryString.parse(window.location.search);
    if (product && product.text_id && !isSinglePage) {
      this.changeHistory(product.text_id, isSinglePage);
    }
    this.state = {
      scrollTop,
      isSinglePage,
      query,
    };
  }

  componentDidMount() {
    const { history, countryCurrency } = this.props;
    this.unlisten = history.listen(() => {
      this.closeModal();
    });
    Emitter.addListener('MODAL_CLOSE', this.removeHistory);
    const {
      value: { product },
    } = this.props;
    analytics.ecom(
      'detail',
      getEcomDataToAnalytics({ product, qty: 1 }),
      countryCurrency
    );
    analytics.fbPixel(
      'ViewContent',
      getFbPixelDataToCart(product, countryCurrency)
    );
  }

  componentWillReceiveProps({
    value: { product: { text_id: textId } = {} } = {},
  }) {
    const { isSinglePage } = this.state;
    const { value } = this.props;
    if (textId !== value.product.text_id) {
      this.changeHistory(textId, isSinglePage);
    }
  }

  componentWillUnmount() {
    Emitter.removeListener('MODAL_CLOSE', this.removeHistory);
    this.removeHistory();
    this.unlisten();
  }

  closeModal = () => {
    const { scrollTop } = this.state;
    Scroll.animateScroll.scrollTo(scrollTop, {
      duration: 0,
    });
    Emitter.emit('MODAL_CLOSE');
  };

  changeHistory = (textId, isSinglePage) => {
    const { location: { pathname } = {} } = this.props;
    if (
      window.history.pushState &&
      pathname.includes('menu') &&
      !isSinglePage
    ) {
      // Слэш в конце ссылки убран специально для того, чтоб браузер
      // не сохранял позицию скролла при обновлении страницы с открытой модалкой

      window.history.pushState({}, null, `${pathname}${textId}`);
    }
  };

  removeHistory = () => {
    const { location: { pathname } = {} } = this.props;
    const { isSinglePage, query } = this.state;
    if (
      window.history.replaceState &&
      pathname.includes('menu') &&
      !isSinglePage
    ) {
      window.history.replaceState({}, null, pathname);
      if (query && !!Object.keys(query).length) {
        replaceQuery(query);
      }
    }
  };

  render() {
    const { value } = this.props;
    const { product, id } = value;
    const { isMobile } = this.props;

    const modalCard = cn(style.modalCard, {
      [style.noAdditional]: isMobile,
    });

    return (
      <div className={modalCard}>
        {product && (
          <CardComponent product={product} cartId={id} position="modal" />
        )}
        {!isMobile && product && <Additional product={product} />}
      </div>
    );
  }
}

const mapStateToProps = ({ countries }) => ({
  countryCurrency: countries.country.currency,
});

export default connect(mapStateToProps)(withRouter(withBreakpoints(CardModal)));
