import { useTranslation } from 'react-i18next';
import useUserBonuses from 'frontend/data/user/user-bonuses/use-user-bonuses';
import { formaterDate } from 'src/shared/lib/date';
import { route } from 'modules/route';
import { useHistory } from 'react-router';
import useQueryParams from 'src/shared/lib/useQueryParams';
import style from './userPromoCode.module.scss';

function UserPromoCode() {
  const { t } = useTranslation();
  const { data } = useUserBonuses();
  const { set, getAll } = useQueryParams();
  const { push } = useHistory();

  const applyPromoCode = (promoCode: string) => {
    set('userProfilePromo', promoCode);
    push({ pathname: route('cart'), search: getAll().toString() });
  };

  if (!data || !data.promo.length) {
    return null;
  }

  return (
    <div className={style.wrapper}>
      <h2 className={style.title}>{t('BlockProducts.user_promo_code')}</h2>
      {data.promo.map(({ promocode, name, expiry, action }) => (
        <div key={promocode} className={style.container}>
          <div className={style.promoCode}>
            <span className={style.text}>
              {name}!&nbsp;Действителен до&nbsp;
              {formaterDate(expiry, 'dd.MM.yyyy')}
            </span>
            <div className={style.block}>
              <span className={style.promo}>{promocode}</span>
              <button
                type="button"
                className={style.btn}
                onClick={() => applyPromoCode(promocode)}
              >
                {t('UserPromoCode.apply')}
              </button>
            </div>
            <a href={action} className={style.info}>
              {t('UserPromoCode.info_stock')}
            </a>
          </div>
        </div>
      ))}
    </div>
  );
}

export default UserPromoCode;
