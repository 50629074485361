import './Menu.scss';
import { Component } from 'react';
import globalStore from 'modules/global-store';
import { route } from 'modules/route';
import { withTranslation } from 'react-i18next';
import * as session from 'modules/session';
import { ExternalImage } from 'shared/ui/image';
import { connect } from 'react-redux';
import { SmartLink } from 'shared/ui/smart-link';
import CustomText from '../../Components/CustomText/CustomText';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs';
import CustomHelmet from '../../Components/CustomHelmet';

class Menu extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.links = [
      {
        href: route(),
        text: t('localization.Main'),
      },
      {
        text: t('localization.Menu'),
      },
    ];
  }

  render() {
    const menu = globalStore.get('menu', []);
    const isMobile = session.get('isMobile', false);
    const { t, mainDomain } = this.props;
    return (
      <div className="page-container">
        <CustomHelmet title={t('localization.Menu')} />
        <div className="page-menu">
          <div
            className="inner-part-B"
            style={{ paddingBottom: '80px', position: 'relative' }}
          >
            <div className="page-menu__wrap">
              <div className="page-menu__breadcrumbs">
                <Breadcrumbs links={this.links} />
              </div>
              <h1 className="page-title">{t('localization.Menu')}</h1>
              <div className="menu-container">
                {menu
                  .filter(({ visible }) => !!visible)
                  .map((item) => {
                    let image;

                    if (isMobile && item.images_mobile) {
                      image = item.images_mobile[0];
                    } else if (item.images_desktop) {
                      image = item.images_desktop[0];
                    } else {
                      image = undefined;
                    }

                    const { text_id, title } = item;
                    return (
                      <SmartLink
                        href={`/menu/${text_id}`}
                        className="menu-item"
                        key={item.id}
                      >
                        <ExternalImage
                          src={image?.filename}
                          mainDomain={mainDomain}
                          alt={title}
                          width={350}
                          height={280}
                        />
                        <span className="menu-item__title">{title}</span>
                      </SmartLink>
                    );
                  })}
              </div>
            </div>
            <CustomText className="custom-text-wrap" />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ countries }) => ({
  mainDomain: countries.country.main_domain,
});

export default connect(mapStateToProps)(withTranslation()(Menu));
