import { useEffect, useState } from 'react';
import { deliverySliceSelectors } from 'src/entities/delivery';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'shared/lib/store';
import PageTitle from 'src/shared/ui/page-title/PageTitle';
import { Media } from 'shared/lib/media';
import { useSelectCity } from 'src/entities/cities';
import styles from './deliveryHeader.module.scss';

function DeliveryHeader() {
  const { city_name } = useSelectCity();

  const { deliveryData, deliveryError } = useAppSelector(
    deliverySliceSelectors.selectDeliveryState
  );

  const [isDelivery, setIsDelivery] = useState<boolean | null>(null);

  useEffect(() => {
    if (deliveryError) {
      setIsDelivery(false);
      return;
    }
    if (!deliveryData) return;
    setIsDelivery(true);
  }, [deliveryData, deliveryError]);

  const { t } = useTranslation();

  let header;
  switch (isDelivery) {
    case null:
      header = `${t('CityAvg.Delivery by the city')} ${city_name}`;
      break;
    case true:
      header = t('CityAvg.To your address');
      break;
    case false:
      header = `${t('CityAvg.Delivery by the city')} ${city_name}`;
      break;
    default:
      header = t('CityAvg.Error');
  }

  return (
    <Media greaterThan="laptop">
      <PageTitle className={styles.deliveryTitle} header={header} />
    </Media>
  );
}

export default DeliveryHeader;
