import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CODES,
  useRegisterTransactionQuery,
} from '../api/register-transaction-api';
import styles from './online-payment.module.scss';

export function OnlinePayment({ order_hash }: { order_hash: string }) {
  const { t } = useTranslation();

  const { data, isSuccess, isFetching, isError } = useRegisterTransactionQuery({
    order_hash,
    return_url: `${window.location.href}?afterOnlinePayment=true`,
  });

  useEffect(() => {
    if (data?.data?.paymentFormUrl) {
      window.location.replace(data.data.paymentFormUrl);
    }
  }, [data]);

  const getMessage = (code?: number) => {
    if (code === CODES.ORDER_PAYMENT_ORDER_NOT_FOUND) {
      return t('OrderPayment.unknownOrderNumber');
    }
    return t('OrderPayment.paymentError');
  };

  const successMessage =
    data?.code !== 200
      ? getMessage(data?.code)
      : t('OrderPayment.toPaymentPage');

  return (
    <section className={styles.root}>
      <h2 className={styles.title}>{t('OrderPayment.thanks')}!</h2>
      <p className={styles.message}>
        {isFetching && t('OrderPayment.toPaymentPage')}
        {isSuccess && successMessage}
        {isError && t('OrderPayment.paymentError')}
      </p>
    </section>
  );
}
