import * as session from 'modules/session';
import { useCurrency } from 'src/entities/countries';
import CustomHelmet from '../../Components/CustomHelmet';

function SingleProductHelmet({
  product: { title, text_id, weight, price, images = [], composition = [] },
  pathname,
}) {
  const { protocol, location } = session.get() || {};
  const seoCompos = composition.map(({ title }) => title).join(',');
  const priceCurrency = useCurrency();

  const seoScript = {
    '@context': 'http://schema.org/',
    '@type': 'Product',
    description: seoCompos,
    name: title,
    alternateName: text_id,
    image: `${protocol}://${location}/img/${images[0] && images[0].filename}`,
    weight: {
      '@type': 'QuantitativeValue',
      value: weight, // вес
    },
    offers: {
      '@type': 'Offer',
      availability: 'http://schema.org/InStock',
      url: `${protocol}://${location}${pathname}`,
      price,
      priceCurrency,
    },
  };

  return (
    <CustomHelmet
      title={title}
      type="object"
      image={`${protocol}://${location}/img/${images[0] && images[0].filename}`}
      ogTitle={title}
      ogDescription={seoCompos}
      script={[
        {
          type: 'application/ld+json',
          innerHTML: JSON.stringify(seoScript),
        },
      ]}
    />
  );
}

export default SingleProductHelmet;
