import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';
import api from 'modules/helpers/api';
import globalStore from '../../../modules/global-store';
import style from './Subscribe.module.scss';
import ErrorPage from '../../Pages/ErrorPage/ErrorPage';
import { stripCity } from '../../../modules/helpers/url-helper';

class Subscribe extends Component {
  constructor(props) {
    super(props);

    this.subscribeRoutes = {
      '/subscribe/email/confirm/': 'subscribe.confirm',
      '/subscribe/email/unsubscribe/': 'subscribe.unsubscribe',
    };

    this.state = {
      subscribe: {},
      isError: false,
      isLoad: false,
    };
  }

  componentDidMount() {
    const { location: { search, pathname } = {} } = this.props;
    const { text_id } = globalStore.get('current_city', {});
    const query = queryString.parse(search) || {};
    const { email, token } = query;
    if (!email || !token) return;
    const path = stripCity(pathname, text_id);
    const route = this.subscribeRoutes[path];

    if (route) {
      const status = route.includes('unsubscribe') ? 'unsubscribe' : 'confirm';
      api(route, query)
        .then(() => {
          globalStore.set('subscribe', { email, token, status });
          this.setState({
            subscribe: {
              email,
              token,
              status,
            },
            isLoad: true,
          });
        })
        .catch((e) => {
          console.log(e);
          this.setState({
            isError: true,
            isLoad: true,
          });
        });
    }
  }

  render() {
    const { subscribe = {}, isError = false, isLoad = false } = this.state;
    const isErrorPage = isError || !subscribe || !Object.keys(subscribe).length;
    if (isErrorPage && isLoad) return <ErrorPage />;
    const { t } = this.props;
    const { email = '', status } = subscribe || {};
    const isUnsubscribe = status === 'unsubscribe';

    return (
      <div className="page-container">
        <div className="inner-part-B">
          {!isLoad ? (
            <div className="preloader">
              <span className="preloader__one" />
              <span className="preloader__two" />
              <span className="preloader__three" />
            </div>
          ) : (
            <div className={style.subscribeContainer}>
              <h1 className="page-title">
                {isUnsubscribe
                  ? t('Subscribe.canceled_title')
                  : t('Subscribe.subscribe_succes')}
              </h1>
              <p>
                {isUnsubscribe
                  ? t('Subscribe.updates_canceled')
                  : t('Subscribe.updatesWillSent')}{' '}
                <span>{email}</span>
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Subscribe);
