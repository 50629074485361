import './MobileMenu.scss';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { mobileMenuEmitter } from 'modules/event-emitters';
import globalStore from 'modules/global-store';
import * as session from 'modules/session';
import { analytics } from 'frontend/analytics/analytics';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import type { IMenu } from 'src/entities/menu';
import { SelectLanguageMobile } from 'features/select-language';
import { useAppSelector } from 'shared/lib/store';
import { headerMenuSliceSelectors } from 'src/entities/header-menu';
import { useSelectedCountry } from 'src/entities/countries';
import { useSelectCityModal } from 'features/geopositioning';
import { useSelectCity } from 'src/entities/cities';
import { SmartLink } from 'shared/ui/smart-link';
import Icon from '../../Elements/Icon/Icon';

export interface IMenuList {
  link: string;
  title: string;
  id: number;
  icon?: string;
  svg?: string;
}

export interface ISidebarItem {
  title: string;
  url: string;
  icon?: string;
  additional?: string | null | undefined | object;
  order?: number;
}

export interface ISidebarItemReduse {
  items: ISidebarItem[];
  urls: string[];
}

function MobileMenu() {
  const { t } = useTranslation();
  const [menuList, setMenuList] = useState<IMenuList[]>([]);
  const [isClosed, setIsClosed] = useState(true);
  const history = useHistory();
  const selectCityModal = useSelectCityModal();
  const menu = useAppSelector(headerMenuSliceSelectors.selectHeaderMenu);

  const iconList = [
    { id: 1, filename: 'rolls' },
    { id: 2, filename: 'baked' },
    { id: 3, filename: 'sets' },
    { id: 708, filename: 'sushi' },
    { id: 710, filename: 'nutriment' },
    { id: 709, filename: 'wok' },
    { id: 711, filename: 'pizza' },
    { id: 712, filename: 'salads' },
    { id: 713, filename: 'soups' },
    { id: 714, filename: 'hotter' },
    { id: 716, filename: 'blunch' },
    { id: 717, filename: 'lunch' },
    { id: 718, filename: 'beverages' },
    { id: 719, filename: 'additionally' },
    { id: 1217, filename: 'postnoe-menu' },
  ];
  const closeMenu = () => {
    setIsClosed(true);
    document.body.classList.remove('hidden');
  };

  const openMenu = () => {
    setIsClosed(false);
    document.body.classList.add('hidden');
  };

  useEffect(() => {
    const category: IMenu[] = globalStore.get('menu');
    const newMenuList: IMenuList[] = [];
    category.forEach((item) => {
      const icon = iconList.find((icon) => item.id === icon.id);
      if (item.visible) {
        newMenuList.push({
          link: item.text_id,
          title: item.title,
          id: item.id,
          icon: icon ? icon.filename : undefined,
          svg:
            item.images_site && item.images_site[0] && item.images_site[0].data
              ? item.images_site[0].data
              : undefined,
        });
      }
    });
    setMenuList(newMenuList);

    mobileMenuEmitter.addListener('MainMenu.OpenMenu', openMenu);
    const unlisten = history.listen(closeMenu);

    return () => {
      mobileMenuEmitter.removeListener('MainMenu.OpenMenu', openMenu);
      unlisten();
    };
  }, [history]);

  const sort = (sidebarItems: ISidebarItem[]) => {
    const orders = [
      { url: 'profile', order: 0 },
      { url: 'menu', order: 1 },
      { url: 'delivery', order: 2 },
      { url: 'addresses', order: 3 },
      { url: 'akcii', order: 4 },
      { url: 'konkurs', order: 5 },
      { url: 'fr-new.sushiwok.ru', order: 6 },
      { url: 'news', order: 7 },
      { url: 'about', order: 8 },
    ];

    sidebarItems = sidebarItems.map((item) => {
      const order = orders.find((order) => {
        return typeof item.url === 'string'
          ? item.url.includes(order.url)
          : false;
      });
      item.order = order ? order.order : 100;
      return item;
    });
    return sidebarItems.sort((a, b) => {
      return (a.order || 0) - (b.order || 0);
    });
  };
  const getSidebarItems = () => {
    let sidebarItems: ISidebarItem[] = [
      {
        title: t('MainMenu.profile'),
        url: 'profile',
        icon: 'people',
        additional: null,
        order: 0,
      },
      {
        title: t('MainMenu.news'),
        url: 'news',
        icon: 'news',
        additional: null,
        order: 0,
      },
      {
        title: t('MainMenu.competitions'),
        url: 'konkurs',
        icon: 'competitions',
        additional: null,
        order: 0,
      },
      {
        title: t('MainMenu.stocks'),
        url: 'akcii',
        icon: 'sale',
        additional: null,
        order: 0,
      },
    ];
    const favorites = {
      title: t('MainMenu.favorites'),
      url: 'profile/favorites',
      icon: 'selected',
      additional: null,
      order: 0,
    };

    const header_menu =
      menu &&
      Array.isArray(menu) &&
      menu.map(({ title, url }) => {
        return { title, url };
      });
    const user = session.get('user');
    if (header_menu) {
      sidebarItems.splice(1, 0, ...header_menu);
    }
    if (user) {
      sidebarItems.push(favorites);
    }

    /**
     * на случай если будут встречаться варианты
     * /akcii/ и /akcii
     * отфильтруем массив
     * */
    sidebarItems = sidebarItems.reduce(
      (accumulator: ISidebarItemReduse, item: ISidebarItem) => {
        const { items, urls } = accumulator;
        const url = item.url.replace(/[/]/g, '');
        if (urls.indexOf(url) < 0) {
          urls.push(url);
          items.push(item);
        }
        return accumulator;
      },
      {
        items: [],
        urls: [],
      }
    ).items;
    return sort(sidebarItems);
  };

  const { phone = '', city_name } = useSelectCity();
  const hasPhone = !!phone;
  const sidebarItems = getSidebarItems();
  const { isUkraine } = useSelectedCountry();
  return (
    <div>
      <div
        className={`wrapper-content ${isClosed ? '' : 'active'}`}
        onClick={closeMenu}
      />
      <aside className={`mobile-sidebar ${isClosed ? '' : 'active'}`}>
        <ul className="mobile-sidebar__group">
          <li
            onClick={() => {
              selectCityModal.show();
              closeMenu();
            }}
          >
            <span className="mobile-sidebar__icon-container">
              <i
                className={cn('icon-mobile', 'icon-mobile__map', {
                  ua: isUkraine,
                })}
              />
            </span>
            <span className="mobile-sidebar__wrapper">
              <span className="mobile-sidebar__text-container">
                <strong>{city_name}</strong>
                <span>{t('MainMenu.Change_city')}</span>
              </span>
              <span className="mobile-sidebar__arrow-container">
                <i className="icon-mobile-arrow" />
              </span>
            </span>
          </li>
          {hasPhone && (
            <li>
              <span className="mobile-sidebar__icon-container">
                <i
                  className={cn('icon-mobile', 'icon-mobile__phone', {
                    ua: isUkraine,
                  })}
                />
              </span>
              <span className="mobile-sidebar__wrapper">
                <span className="mobile-sidebar__text-container">
                  <strong>
                    <a
                      href={`tel:${phone.replace(/ /g, '')}`}
                      onClick={() => analytics.fbPixel('Contact')}
                      className="classToLink"
                    >
                      {' '}
                      {phone}
                    </a>
                  </strong>
                  <span>{t('MainMenu.make_call')}</span>
                </span>
                <span className="mobile-sidebar__arrow-container">
                  <i className="icon-mobile-arrow" />
                </span>
              </span>
            </li>
          )}
        </ul>
        <ul className="mobile-sidebar__group">
          <SelectLanguageMobile />
        </ul>
        <ul className="mobile-sidebar__group">
          <li className="mobile-sidebar__products-menu">
            {menuList.map(({ link, title, icon, svg }) => {
              return (
                <SmartLink
                  href={`/menu/${link}`}
                  activeClassName="active"
                  key={`mobile-${link}`}
                >
                  {svg ? (
                    <div
                      className="svg-wrapper svg-wrapper--updated"
                      dangerouslySetInnerHTML={{
                        __html: Buffer.from(svg, 'base64').toString('binary'),
                      }}
                    />
                  ) : icon ? (
                    <Icon w="96" h="96" icon={`${icon}`} />
                  ) : undefined}
                  <span>{title}</span>
                </SmartLink>
              );
            })}
          </li>
        </ul>
        <ul className="mobile-sidebar__group">
          {sidebarItems.map((item) => {
            if (!item.icon) {
              switch (true) {
                case item.url.includes('menu'):
                  item.icon = 'menu';
                  break;
                case item.url.includes('about'):
                  item.icon = 'about';
                  break;
                case item.url.includes('.sushiwok.'):
                  item.icon = 'franchise';
                  break;
                case item.url.includes('delivery') ||
                  item.url.includes('dostavka'):
                  item.icon = 'delivery';
                  break;
                case item.url.includes('akcii'):
                  item.icon = 'sale';
                  break;
                case item.url.includes('addresses'):
                  item.icon = 'shop';
                  item.additional = t('MainMenu.FindAStoreForPickup');
                  break;
                default:
                  item.icon = 'news';
                  break;
              }
            }

            if (item.icon === 'franchise') return '';

            return (
              <li key={item.url}>
                <SmartLink href={item.url}>
                  <span className="mobile-sidebar__icon-container">
                    <i className={`icon-mobile icon-mobile__${item.icon}`} />
                  </span>
                  <span className="mobile-sidebar__wrapper">
                    <span className="mobile-sidebar__text-container">
                      <strong>{item.title}</strong>
                      {item.additional ? (
                        <span>{item.additional}</span>
                      ) : undefined}
                    </span>
                    <span className="mobile-sidebar__arrow-container">
                      <i className="icon-mobile-arrow" />
                    </span>
                  </span>
                </SmartLink>
              </li>
            );
          })}
        </ul>
      </aside>
    </div>
  );
}

export default MobileMenu;
