import { Component } from 'react';
import autobind from 'autobind-decorator';
import * as session from 'modules/session';
import globalStore from 'modules/global-store';
import { withTranslation } from 'react-i18next';
import cn from 'classnames';
import Button from 'src/shared/ui/Button/Button';
import { withCurrency } from 'src/entities/countries';
import { getBanknotes } from '../../helpers';
import Emitter from '../../../Emitters';
import styles from './paymentModal.module.scss';
import modalStyles from '../../main-card-modal/mainCartModal.module.scss';

class CourierModal extends Component {
  constructor(props) {
    super(props);
    const { banknotes, country_id } = globalStore.get('current_city');
    const {
      value: { payment_type },
    } = this.props;
    const isCourierCash = payment_type === 'ch';
    const isCardOnline = payment_type === 'co';
    const isCourierCard = payment_type === 'cc';

    this.state = {
      banknotes,
      country_id,
      isCourierCash,
      isCardOnline,
      isCourierCard,
      disableButton: isCourierCash,
    };
  }

  setValue = (event, value) => {
    event.preventDefault();
    this.input.value = String(parseInt(value, 10) || 0);
    this.changeHandler({ target: { value: String(parseInt(value, 10) || 0) } });
  };

  getCardOnlineAdditionalText = (t) => {
    const {
      order: { organizationContactInformation },
    } = session.get('cart');

    if (!organizationContactInformation) {
      return <p>{t('PaymentModal.whenYouClickOnTheButtonCheckout')}</p>;
    }

    return (
      <>
        <p>{t('PaymentModal.whenYouClickOnTheButtonCheckout')}</p>
        <p>
          Оформляя заказ, вы подтверждаете, что ознакомились с{' '}
          <a
            className="classToLink"
            href="/page/sber-acquiring"
            target="_blank"
          >
            процессом передачи персональных данных
          </a>{' '}
          и{' '}
          <a className="classToLink" href="/dannye-prodavca" target="_blank">
            контактными данными организации
          </a>
          , оказывающей услугу.
        </p>
      </>
    );
  };

  @autobind
  changeHandler({ target }) {
    target.value = target.value.replace(/[^0-9]/g, '');

    const { isCourierCash } = this.state;
    if (isCourierCash) {
      const {
        value: { total },
      } = this.props;
      const disableButton =
        target.value === '' || (+target.value !== 0 && +target.value < +total);
      this.setState({
        disableButton,
      });
    }
  }

  @autobind
  confirmCash({ target }) {
    target.disabled = true;
    const { value: change_from } = this.input;
    Emitter.emit('CHECKOUT', change_from);
    Emitter.emit('DO_NOT_CLOSE_MODAL', true);
  }

  @autobind
  confirm({ target }) {
    target.disabled = true;
    Emitter.emit('CHECKOUT');
    Emitter.emit('DO_NOT_CLOSE_MODAL', true);
  }

  render() {
    const {
      value: { total },
      t,
      currency,
    } = this.props;
    const {
      banknotes,
      isCourierCash,
      isCourierCard,
      isCardOnline,
      disableButton,
      country_id,
    } = this.state;
    const changeNominals = getBanknotes(total, country_id);

    return (
      <div className={styles.courierCashModal}>
        <div className={modalStyles.titleContainer}>
          {isCourierCash || isCourierCard ? (
            <p>
              {isCourierCash
                ? t('PaymentModal.what_is_the_minimum_amount_need_to_change')
                : t('PaymentModal.payment_to_the_courier_card')}
            </p>
          ) : (
            <p>{t('PaymentModal.card_payment_online')}</p>
          )}
        </div>
        <div className={styles.textRow}>
          <p>
            {t('PaymentModal.the_amount_of_your_order_is')} {total}&nbsp;
            {currency}
          </p>
        </div>
        {isCourierCash && (
          <div className={styles.inputRow}>
            <label htmlFor="deal-input">
              {t('PaymentModal.what_is_the_minimum_amount_need_to_change')} *
            </label>
            {banknotes.length ? (
              <span>
                {t('PaymentModal.weDoNotAcceptBanknotes')}{' '}
                {banknotes.join(', ')}&nbsp;{currency}
              </span>
            ) : null}
            <input
              type="text"
              id="deal-input"
              maxLength={5}
              ref={(input) => (this.input = input)}
              pattern="^[\d]{3,6}$"
              onChange={this.changeHandler}
            />
            {changeNominals && changeNominals.length && (
              <div className={styles.banknotes}>
                {changeNominals.map((banknote, index) => (
                  <button
                    type="button"
                    onClick={(e) => this.setValue(e, banknote)}
                    key={`${banknote}-${index}`}
                  >
                    {banknote !== 'no_change'
                      ? banknote
                      : t(`PaymentModal.${banknote}`)}
                  </button>
                ))}
              </div>
            )}
          </div>
        )}
        {isCardOnline && (
          <div className={styles.textRow}>
            {this.getCardOnlineAdditionalText(t)}
          </div>
        )}
        <div className={modalStyles.btnContainer}>
          <Button
            disabled={disableButton}
            className={cn('sw-button', styles.swButton)}
            onClick={(e) =>
              isCourierCash ? this.confirmCash(e) : this.confirm(e)
            }
            text={t('PaymentModal.place_your_order')}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(withCurrency(CourierModal));
