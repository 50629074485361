import { useState } from 'react';
import globalStore from 'modules/global-store';
import ballonImage from 'assets/images/icon/ballon-icon.svg';
import uaLocationIcon from 'assets/images/icon/uaLocationIcon.svg';
import cn from 'classnames';
import { useSelectedCountry } from 'src/entities/countries';
import { IStore } from 'src/entities/stores';
import { useTranslation } from 'react-i18next';
import { LinkTabs } from 'src/shared/ui/link-tabs';
import { StoresTable } from 'src/entities/stores/ui/stores-table';
import Button from 'src/shared/ui/Button/Button';
import { Suggest } from 'features/address-suggest';
import { PickupMap } from 'features/stores-map';
import { modalEmitter } from 'modules/event-emitters';
import { Media } from 'shared/lib/media';
import styles from './list.module.scss';

function StoresPageContent({ stores }: { stores: IStore[] }) {
  const { is_delivery } = globalStore.get('current_city');
  const { t } = useTranslation();
  const { isUkraine } = useSelectedCountry();
  const [currentStoreId, setCurrentStoreId] = useState<number | null>(null);
  const [invalidAddress, setInvalidAddress] = useState<boolean | null>(false);

  const items = [
    {
      url: `/delivery`,
      title: t('localization.Delivery'),
    },
    {
      url: `/addresses`,
      title: t('localization.Stores'),
    },
  ];

  const addReview = (store: IStore) => {
    modalEmitter.emit('Review.Modal.Open', store);
  };

  const infoText = '';
  const buttonText = t('Map.send_review');
  const LocatioIcon = isUkraine ? uaLocationIcon : ballonImage;

  return (
    <>
      <div className={styles.toolbar}>
        <div className={styles.toolbarLeft}>
          <Media greaterThan="laptop">
            <h1 className={cn('page-title', styles.pageTitle)}>
              {t('List.find nearest store')}{' '}
            </h1>
          </Media>
          <div className={styles.suggestionWrap}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, react/jsx-no-comment-textnodes */}
            <div
              role="button"
              className={styles.geolocationWrap}
              tabIndex={0}
              // onClick={getPosition}
            >
              <LocatioIcon />
            </div>
            <div style={{ flexGrow: '1' }}>
              <span className={styles.addressTitle}>
                {t('List.your address')}
              </span>
              <Suggest
                placeholder={t('List.Enter street or subway')}
                // onChange={onSuggestChange}
              />
              {invalidAddress && (
                <div className={styles.addressError}>
                  {' '}
                  {t('List.Update address')}{' '}
                </div>
              )}
            </div>
          </div>
          <Button
            className={styles.buttonContainer}
            text={t('List.find nearest store')}
            // onClick={getPosition}
          />
        </div>
        {is_delivery && (
          <div className={styles.storeRoutes}>
            <LinkTabs items={items} />
          </div>
        )}
      </div>
      <div className={styles.content}>
        <div className={styles.map}>
          <PickupMap
            stores={stores}
            actionFn={addReview}
            currentStoreId={currentStoreId}
            setCurrentStoreId={setCurrentStoreId}
            infoText={infoText}
            buttonText={buttonText}
            onGetAddressError={(e: unknown) => {
              if (e === 'Address not found' || e === 'Address not complete') {
                setInvalidAddress(true);
              }
            }}
          />
        </div>
        <div className={styles.list}>
          <StoresTable stores={stores} setCurrentStoreId={setCurrentStoreId} />
        </div>
      </div>
    </>
  );
}

export default StoresPageContent;
