/* import { eventBus } from 'modules/event-emitters';
import styles from './styles.module.scss'; */
import { useState } from 'react';
import { useResizeWindow } from 'frontend/hooks';
import styles from './styles.module.scss';

interface IBanner {
  src: string;
  link: string;
  /*   device: 'iPhone' | 'android'; */
  alt?: string;
}
function Banner({ alt = '', src, link /* device */ }: IBanner) {
  const [visible, setVisible] = useState(
    !localStorage.getItem('isVisibleBanner')
  );

  const size = useResizeWindow();

  const closeBanner = () => {
    setVisible(false);
    localStorage.setItem('isVisibleBanner', 'true');
  };

  /*   const clickBanner = () => {
    if (device === 'iPhone') {
      eventBus.emitEvent('AppStoreClick');
    }
    if (device === 'android') {
      eventBus.emitEvent('GooglePlayClick');
    }
  }; */

  if (!size.isMobile || !src || !visible) {
    return null;
  }

  return (
    <div className={styles.banner}>
      <a href={link} /* onClick={clickBanner} */>
        <img alt={alt} src={`/img/${src}`} width={430} height={230} />
      </a>
      <span className={styles.closeButton} onClick={closeBanner} />
    </div>
  );
}

export default Banner;
