import { Component } from 'react';
import { Helmet } from 'react-helmet';
import { seoEmitter } from 'modules/event-emitters';
import globalStore from 'modules/global-store';

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cnt: 0,
    };
  }

  componentDidMount() {
    seoEmitter.addListener('SEO_UPDATE', this.updateSeo);
    seoEmitter.addListener('UPDATE', this.reRender);
  }

  componentWillUnmount() {
    seoEmitter.removeListener('SEO_UPDATE', this.updateSeo);
    seoEmitter.removeListener('UPDATE', this.reRender);
  }

  getSeoParam(paramName, value) {
    const seo = { ...globalStore.get('seo', {}) };
    const { city_name } = globalStore.get('current_city', {});

    const patterns = {
      city: city_name,
      h1: value,
    };

    return seo[paramName] ? this.patterns(seo[paramName], patterns) : value;
  }

  updateSeo = () => {
    let { cnt } = this.state;
    cnt++;
    this.setState({ cnt });
  };

  reRender = () => {
    this.forceUpdate();
  };

  patterns(str, patterns) {
    for (const pattern in patterns) {
      const reg = new RegExp(`{${pattern}}`, 'gi');
      str = str.replace(reg, patterns[pattern]);
    }

    return str;
  }

  render() {
    const {
      type,
      title,
      image,
      ogTitle,
      ogDescription,
      script,
      noindex = false,
    } = this.props;

    const seoParams = {
      title: this.getSeoParam('title', title),
      meta: [
        {
          name: 'description',
          content: this.getSeoParam('description', title),
        },
        { name: 'keywords', content: this.getSeoParam('keywords', title) },
      ],
    };

    if (ogTitle) {
      seoParams.meta.push({ property: 'og:title', content: ogTitle });
    } else {
      seoParams.meta.push({
        property: 'og:title',
        content: this.getSeoParam('title', title),
      });
    }

    if (ogDescription) {
      seoParams.meta.push({
        property: 'og:description',
        content: ogDescription,
      });
    } else {
      seoParams.meta.push({
        property: 'og:description',
        content: this.getSeoParam('description', title),
      });
    }

    if (type) {
      seoParams.meta.push({ property: 'og:type', content: type });
    }

    if (image) {
      seoParams.meta.push({ property: 'og:image', content: image });
    }

    if (script) {
      seoParams.script = script;
    }

    if (noindex) {
      seoParams.meta.push({ name: 'robots', content: 'noindex' });
    }

    return <Helmet {...seoParams} />;
  }
}
