import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

export const useCityAndLang = () => {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const { city } = useParams<{ city: string }>();

  return { city, lang };
};
