import { createSelector, createSlice } from '@reduxjs/toolkit';
import type {
  AddressError,
  IFullAddress,
} from 'shared/third-party-libs/commonMap';
import { deliveryCheckApi } from '../api/deliveryCheckApi';
import {
  IDeliveryCheckOk,
  IDeliveryCheckStopList,
  IDeliveryError,
} from './types';
import { getAddressApi } from '../api/getAddressApi';

const initState: {
  address: IFullAddress | AddressError | null;
  delivery: IDeliveryCheckOk | IDeliveryError | null;
} = {
  address: null,
  delivery: null,
};

const isDeliveryError = (
  resp: IDeliveryCheckOk | IDeliveryCheckStopList | IDeliveryError | null
): resp is IDeliveryError => {
  if (!resp) return false;
  return 'type' in resp;
};

const isDeliveryCheckStopList = (
  resp: IDeliveryCheckOk | IDeliveryCheckStopList | IDeliveryError
): resp is IDeliveryCheckStopList => {
  return !!(resp as IDeliveryCheckStopList).stop_lists;
};

const isAddressError = (
  resp: IFullAddress | AddressError | null
): resp is AddressError => {
  return typeof resp === 'string';
};

export const deliverySlice = createSlice({
  name: 'delivery',
  initialState: initState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      deliveryCheckApi.endpoints.deliveryCheck.matchFulfilled,
      (state, { payload }) => {
        if (isDeliveryCheckStopList(payload)) {
          state.delivery = {
            type: 'product_on_stop',
            data: payload.stop_lists,
          };
          return;
        }
        state.delivery = payload;
      }
    );
    builder.addMatcher(
      deliveryCheckApi.endpoints.deliveryCheck.matchRejected,
      (state, { payload }) => {
        state.delivery = payload as unknown as IDeliveryError;
      }
    );
    builder.addMatcher(
      getAddressApi.endpoints.getAddress.matchFulfilled,
      (state, { payload }) => {
        state.address = payload;
      }
    );
  },
});

const selectAddress = (state: RootState) => {
  if (!isAddressError(state.delivery.address)) return state.delivery.address;
  return null;
};

const selectAddressError = (state: RootState) => {
  if (isAddressError(state.delivery.address)) return state.delivery.address;
  return null;
};

const selectDelivery = (state: RootState) => {
  if (!isDeliveryError(state.delivery.delivery)) {
    return state.delivery.delivery;
  }
  return null;
};

const selectDeliveryError = (state: RootState) => {
  if (isDeliveryError(state.delivery.delivery)) {
    return state.delivery.delivery;
  }
  return null;
};

const selectDeliveryState = createSelector(
  [selectDelivery, selectDeliveryError],
  (deliveryData, deliveryError) => {
    return { deliveryData, deliveryError };
  }
);

const selectFullAddress = createSelector(
  [selectAddress, selectAddressError],
  (fullAddress, addressError) => {
    return { fullAddress, addressError };
  }
);

export const deliverySliceSelectors = {
  selectDeliveryState,
  selectFullAddress,
};
