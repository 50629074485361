import autobind from 'autobind-decorator';

import { Component } from 'react';
import { userProfileEmitter } from 'modules/event-emitters';
import ProfileComponent from './Info/Info';
import ProfileEditComponent from './Edit/Edit';

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      method: 'info',
    };
  }

  componentDidMount() {
    userProfileEmitter.addListener(
      'User.Profile.ChangeTypeAction',
      this.changeTypeListener
    );

    userProfileEmitter.addListener('User.Profile.Error', this.errorListener);
    userProfileEmitter.addListener('User.Profile.Info', this.infoListener);
  }

  componentWillUnmount() {
    userProfileEmitter.removeListener(
      'User.Profile.ChangeTypeAction',
      this.changeTypeListener
    );

    userProfileEmitter.removeListener('User.Profile.Error', this.errorListener);
    userProfileEmitter.removeListener('User.Profile.Info', this.infoListener);
  }

  @autobind
  changeTypeListener(type) {
    this.setState({
      method: type,
    });
  }

  @autobind
  errorListener(msg) {
    this.errorContainer.innerHTML = msg || '';
  }

  @autobind
  infoListener(msg) {
    this.infoContainer.innerHTML = msg || '';
  }

  renderContent() {
    const { method } = this.state;
    const { updateAddress, orders, addresses } = this.props;

    switch (method) {
      case 'edit':
        return <ProfileEditComponent />;
      default:
        return (
          <ProfileComponent
            addresses={addresses}
            orders={orders}
            updateAddress={updateAddress}
          />
        );
    }
  }

  render() {
    return (
      <div>
        <div ref={(div) => (this.infoContainer = div)} />
        <div ref={(div) => (this.errorContainer = div)} />
        <div>{this.renderContent()}</div>
      </div>
    );
  }
}

export default Profile;
