import './Vacancy.scss';
import { Component } from 'react';
import autobind from 'autobind-decorator';
import globalStore from 'modules/global-store';
import * as session from 'modules/session';
import { route } from 'modules/route/index';
import { modalEmitter } from 'modules/event-emitters/index';
import ssr from 'modules/decorators/ssr';
import api from 'modules/helpers/api';
import Button from 'src/shared/ui/Button/Button';
import VacanciesModal from '../../Components/Vacancies/VacanciesModal/VacanciesModal';
import ErrorComponent from '../../Components/ErrorComponent/ErrorComponent';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs';
import CustomHelmet from '../../Components/CustomHelmet';

class VacancyPage extends Component {
  static LOADED_KEY = 'vacancy_loaded';

  static createDescriptionMarkup(description) {
    return { __html: description };
  }

  static renderDescription(description) {
    return (
      <div
        className="vacancy-item__description"
        dangerouslySetInnerHTML={VacancyPage.createDescriptionMarkup(
          description
        )}
      />
    );
  }

  static initialData = (fetch, params = {}, globalStore) => {
    const { vacancies_item: text_id } = params;
    return fetch('vacancies.get', { text_id })
      .then((result) => globalStore.set('vacancies_item', result))
      .catch((e) => {
        console.error('VacancyPage', e);
        return [];
      });
  };

  constructor(props) {
    super(props);
    this.state = {
      vacancies_item: globalStore.get('vacancies_item'),
    };
  }

  componentDidMount() {
    if (globalStore.get(VacancyPage.LOADED_KEY)) {
      globalStore.unlink(VacancyPage.LOADED_KEY);
    } else {
      VacancyPage.initialData(api, this.props.match.params, globalStore).then(
        () => {
          this.setState({
            vacancies_item: globalStore.get('vacancies_item', {}),
          });
        }
      );
    }
    document.body.style.overflow = '';
  }

  getTitle(vacancy) {
    if (vacancy) {
      const { title = '' } = vacancy;
      return title;
    }
    return '';
  }

  @autobind
  showVacanciesFormModal(event) {
    event.stopPropagation();

    const {
      vacancies_item: { id },
    } = this.state;

    modalEmitter.emit('Vacancies.Modal.Open', id);
  }

  @autobind
  toGenerateBackground(img) {
    const imageSrc = `/img/${img}`;
    const bgStyle = { backgroundImage: `url(${imageSrc})` };

    return <div className="vacancy-item__image" style={bgStyle} />;
  }

  render() {
    const { vacancies_item } = this.state;
    const { t } = this.props;

    if (!vacancies_item) {
      return <div />;
    }

    if (vacancies_item && vacancies_item.length === 0) {
      return (
        <div className="page-container">
          <div className="page-info">
            <div className="inner-part-B">
              <ErrorComponent
                action_link="/vacancy"
                action_text={t('Vacancies.vacancy_get_all')}
              />
            </div>
          </div>
        </div>
      );
    }

    let { description } = vacancies_item;
    const { title, image } = vacancies_item;
    description = description.replace('<hr>', '');

    const links = [
      {
        href: route('/'),
        text: t('localization.Main'),
      },
      {
        href: route('vakansii'),
        text: t('Vacancy.vacancies_title'),
      },
      {
        text: title,
      },
    ];

    return (
      <div className="page-container page-vacancy">
        <CustomHelmet
          title={this.getTitle(vacancies_item)}
          image={`${session.get('protocol')}://${session.get(
            'location'
          )}/img/${image}`}
          ogTitle={this.getTitle(vacancies_item)}
        />
        <div className="inner-part-B">
          <Breadcrumbs links={links} />
          <h1 className="page-title">{title}</h1>
          <div className="vacancy-item">
            {this.toGenerateBackground(image)}
            {VacancyPage.renderDescription(description)}
          </div>
          <Button
            className="sw-button"
            onClick={this.showVacanciesFormModal}
            text={t('localization.Send resume')}
          />

          <VacanciesModal />
        </div>
      </div>
    );
  }
}

export default ssr(VacancyPage);
