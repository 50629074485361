import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { route } from 'modules/route';
import { OnlinePayment, useGetOrderQuery } from 'src/entities/order';
import { OrderInfo } from 'src/frontend/widgets/order-info';
import { useQuery } from 'shared/lib/query';
import ErrorComponent from '../../../Components/ErrorComponent/ErrorComponent';
import Breadcrumbs from '../../../Components/Breadcrumbs/Breadcrumbs';
import CustomHelmet from '../../../Components/CustomHelmet';
import './OrderInfoPage.scss';

type Params = {
  city: string;
  order_hash: string;
  order_salt: string;
};

function OrderInfoPage() {
  const { t, i18n } = useTranslation();
  const { order_hash, order_salt, city } = useParams<Params>();
  const { data, error, isFetching } = useGetOrderQuery({
    order_hash,
    order_salt,
    city,
    lang: i18n.language,
  });
  const params = useQuery();

  const links = [
    {
      href: route('/'),
      text: t('localization.Main'),
    },
    {
      href: route('cart'),
      text: t('OrderInfoPage.cart_title'),
    },
    {
      text: t('OrderInfoPage.delivery_title'),
    },
  ];
  if (isFetching) {
    return null;
  }
  if (error) {
    return <ErrorComponent />;
  }
  if (!data?.data?.order) {
    return null;
  }

  const { order } = data.data;
  const isOnlinePayment = order.payment_type === 'co';
  const isOnlinePaymentStatus =
    order.payment_status === null || order.payment_status === 2;

  const isStartOnlinePaymentProcess =
    isOnlinePayment &&
    isOnlinePaymentStatus &&
    !params.has('afterOnlinePayment'); // payment status doesn't have time to change

  return (
    <div className="page-container order-info-page">
      <div className="inner-part-B">
        <CustomHelmet title={t('OrderInfoPage.delivery_title')} noindex />
        <Breadcrumbs links={links} />
        {isStartOnlinePaymentProcess ? (
          <OnlinePayment order_hash={order_hash} />
        ) : (
          <OrderInfo order={order} order_salt={order_salt} />
        )}
      </div>
    </div>
  );
}

export default OrderInfoPage;
