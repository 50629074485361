/* eslint-disable import/no-duplicates */
import {
  addDays,
  addMinutes,
  eachMinuteOfInterval,
  eachDayOfInterval,
  endOfDay,
  isSameDay,
  max,
  roundToNearestMinutes,
  startOfDay,
  subMinutes,
  format,
  parseISO,
  isValid,
  parse,
  getUnixTime,
  isAfter,
  isBefore,
  isSameMinute,
  getISODay,
  setDay,
  isWithinInterval,
} from 'date-fns';
import { ru, uk, pl, srLatn as me, enUS as en, hy } from 'date-fns/locale';
import { get } from 'lodash';

export const formaterDate = (date: string | Date | unknown, view: string) => {
  if (date instanceof Date && isValid(date)) {
    return format(date, view);
  }
  if (typeof date !== 'string') {
    return date;
  }
  return format(parseISO(date), view);
};

const stringToDate = (date: string) => {
  return parse(date, 'dd.MM.yyyy', new Date());
};

const getDateInSeconds = (date: Date) => {
  return getUnixTime(date);
};

const locales = { ru, ua: uk, pl, me, en, hy };

const formatDayOfWeekToString = (dayOfWeek: number, lang: string) => {
  const referenceDate = setDay(new Date(), dayOfWeek);
  const locale = get(locales, lang);
  // Т.к. в русском и украинском принято сокрашение из 2х букв а в остальных из 3х
  const currentFormat = locale === ru || locale === uk ? 'iiiiii' : 'iii';
  return format(referenceDate, currentFormat, { locale });
};

export const formatDateWithLocale = (
  date: string | Date,
  formatValue: string,
  lang: string
) => {
  return format(typeof date === 'string' ? new Date(date) : date, formatValue, {
    locale: get(locales, lang),
  });
};

const dayOfWeek = getISODay;

const formatHelper = {
  fullDate: 'yyyy-MM-dd HH:mm',
  fullDateWithSeconds: 'yyyy-MM-dd HH:mm:ss',
  time: 'HH:mm',
  timeWithSeconds: 'HH:mm:ss',
  month: 'MM',
  day: 'yyyy-MM-dd',
  dayText: 'EEEE, d MMMM',
  monthText: 'd MMMM yyyy',
} as const;

const formatMonthWithLocale = (stringDate: string, lang: string) => {
  const date = parse(stringDate, formatHelper.fullDateWithSeconds, new Date());
  if (!isValid(date)) {
    console.error('Incorrect date format');
    return format(new Date(), formatHelper.monthText, {
      locale: get(locales, lang),
    });
  }
  return format(date, formatHelper.monthText, {
    locale: get(locales, lang),
  });
};

const formatDateForAttribute = (stringDate: string) => {
  const date = parse(stringDate, formatHelper.day, new Date());
  if (!isValid(date)) {
    console.error('Incorrect date format');
    return format(new Date(), formatHelper.day);
  }
  return format(date, formatHelper.day);
};

const formaterTime = (parsedDate: Date) => {
  return formaterDate(parsedDate, formatHelper.time);
};

const parseTimeWithSeconds = (stringDate: string) => {
  const date = parse(stringDate, formatHelper.timeWithSeconds, new Date());
  if (!isValid(date)) {
    console.error('Incorrect date format');
    return parse('00:00', formatHelper.timeWithSeconds, new Date());
  }
  return date;
};

export {
  addDays,
  addMinutes,
  eachMinuteOfInterval,
  formatDayOfWeekToString,
  eachDayOfInterval,
  endOfDay,
  isSameDay,
  max,
  roundToNearestMinutes,
  startOfDay,
  subMinutes,
  isValid,
  isAfter,
  isBefore,
  format,
  parse,
  isSameMinute,
  dayOfWeek,
  getDateInSeconds,
  stringToDate,
  formatMonthWithLocale,
  formatDateForAttribute,
  formatHelper,
  isWithinInterval,
  parseTimeWithSeconds,
  formaterTime,
};
