import CallCenterInfo from 'src/shared/ui/call-center-info/CallCenterInfo';
import { useGetStoreInfoQuery } from '../../api/storeInfoApi';

interface IStorePhoneLinkProps {
  cityId: number;
  storeId: number;
}

function StorePhoneLink({ storeId, cityId }: IStorePhoneLinkProps) {
  const { data } = useGetStoreInfoQuery({ city: cityId, id: storeId });
  const storePhone = data?.data.store.phone;

  if (!storePhone) return null;

  return <CallCenterInfo phone={storePhone} />;
}

export default StorePhoneLink;
