import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { InternalImage } from 'shared/ui/image';
import { SmartLink } from 'shared/ui/smart-link';
import FranchiseImage from './assets/franchise.png';

function Franchise() {
  const { t } = useTranslation();
  return (
    <div className="page-container">
      <div className="page-info">
        <Helmet title={t('Franchise.franchise')} />
        <div className="inner-part-B">
          <SmartLink href="https://get.sushiwok.ru/?utm_source=sushiwok_ru&utm_medium=banner">
            <InternalImage
              src={FranchiseImage}
              alt={t('Franchise.franchise')}
              width={1000}
              height={500}
            />
          </SmartLink>
        </div>
      </div>
    </div>
  );
}

export default Franchise;
