import './Stocks.scss';
import { Component } from 'react';
import { seoEmitter } from 'modules/event-emitters/index';
import globalStore from 'modules/global-store/index';
import api from 'modules/helpers/api';
import ssr from 'modules/decorators/ssr';
import { analytics } from 'frontend/analytics/analytics';
import { ExternalImage } from 'shared/ui/image';
import CardWrapper from 'shared/ui/card-wrapper/CardWrapper';
import cn from 'classnames';
import { connect } from 'react-redux';
import { SmartLink } from 'shared/ui/smart-link';
import { Media } from 'shared/lib/media';
import { route } from 'modules/route';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs';
import CustomText from '../../Components/CustomText/CustomText';
import SubscribeForm from '../../Components/Modals/SubscribeModal/SubscribeForm';
import CustomHelmet from '../../Components/CustomHelmet';
import styles from './stocks.module.scss';

class Stocks extends Component {
  static LOADED_KEY = 'stocks_loaded';

  static initialData(fetch, params, store) {
    return Promise.all([
      fetch('stock.index').then((result) => store.set('stocks_page', result)),
      fetch('stock.get_categories').then((result) =>
        store.set('stocks_categories', result)
      ),
      fetch('product.list', { is_action: 1 }).then((result) =>
        store.set('action_products', result)
      ),
    ])
      .then(() => [])
      .catch((e) => {
        console.error('Stocks', e);
        return [];
      });
  }

  constructor(props) {
    super(props);
    this.state = {
      stocks: globalStore.get('stocks_page', []),
      stockCategories: globalStore.get('stocks_categories', []),
      openStockId: [],
    };
  }

  componentDidMount() {
    if (globalStore.get(Stocks.LOADED_KEY)) {
      globalStore.unlink(Stocks.LOADED_KEY);
    } else {
      Stocks.initialData(api, {}, globalStore).then(() => {
        this.setState({
          stocks: globalStore.get('stocks_page', []),
          stockCategories: globalStore.get('stocks_categories', []),
        });
      });
    }

    seoEmitter.emit('UPDATE');

    analytics.akciiAttention();
  }

  cropDescription = (description, isMobile) => {
    const crop_mount = isMobile ? 170 : 230;
    if (description.length > crop_mount) {
      return `${description.slice(0, crop_mount)}...`;
    }
    return description;
  };

  renderContent = (item, imgUrl, isMobile) => {
    const { title, category_id, stockpage_title, description_stock, id } = item;
    const { stockCategories = [], openStockId } = this.state;
    const { t, mainDomain } = this.props;
    const { name } =
      stockCategories.find((item) => item.id === category_id) || {};
    const isCrop = isMobile && description_stock.length > 170;
    const isOpen = openStockId.find((action) => action === id);

    const descriptionContainerStyles = cn(styles.descriptionContainer, {
      [styles.hiddenText]: !isOpen && isCrop,
      [styles.shownText]: isOpen && isCrop,
    });

    return (
      <CardWrapper className={styles.cardWrapper}>
        {name && <div className="stock-category">{name}</div>}
        <ExternalImage
          src={imgUrl}
          mainDomain={mainDomain}
          alt={title}
          width={740}
          height={490}
          sizes={{
            mobile: {
              width: 400,
              height: 300,
            },
            tablet: {
              width: 350,
              height: 250,
            },
            desktop: {
              width: 750,
              height: 500,
            },
          }}
        />
        {title || description_stock || stockpage_title ? (
          <div className="stock-text">
            <span className="stock-title">{title}</span>
            <div
              className={descriptionContainerStyles}
              onClick={(e) => isCrop && this.toggleDescription(e, id, isOpen)}
              role="none"
            >
              {description_stock &&
                !isOpen &&
                this.cropDescription(description_stock, isMobile)}
              {description_stock && isOpen && description_stock}
            </div>
            {isOpen && isCrop && (
              <div className="link-more"> {t('Stocks.more')} &#8594;</div>
            )}
          </div>
        ) : undefined}
      </CardWrapper>
    );
  };

  renderItem = (item, isMobile) => {
    const { link, has_static_page, text_id, images } = item;
    let stockLink = '';
    if (has_static_page) {
      stockLink = `/akcii/${encodeURIComponent(text_id)}`;
    } else if (link) {
      stockLink = link;
    }
    const section = isMobile ? 'stock_page_mobile' : 'stock_page';
    const findedSection = images.find((item) => item.section === section);
    if (!findedSection) return null;
    const imgUrl = findedSection?.filename;
    if (stockLink) {
      return (
        <SmartLink className={styles.link} href={stockLink} key={item.id}>
          {this.renderContent(item, imgUrl, isMobile)}
        </SmartLink>
      );
    }
    return (
      <div key={`u-${item.id}`}>
        {this.renderContent(item, imgUrl, isMobile)}
      </div>
    );
  };

  toggleDescription(e, id, isOpen) {
    if (e.target.className !== 'link-more') {
      e.preventDefault();
    } else {
      return;
    }
    const { openStockId } = this.state;

    if (isOpen) {
      const ids = openStockId.filter((idd) => id !== idd);
      this.setState({ openStockId: ids });
    } else {
      const ids = [...openStockId, id];
      this.setState({ openStockId: ids });
    }
  }

  render() {
    const { stocks = [] } = this.state;
    const { t } = this.props;
    const links = [
      { href: route(), text: t('localization.Main') },
      { text: t('localization.Stocks') },
    ];
    const { stocks_subscribe_block: isSubscribeBlock } =
      globalStore.get('current_city');
    const pageName = t('localization.Stocks');

    return (
      <div className="page-container page-stocks">
        <CustomHelmet title={pageName} type="article" />
        <div className="inner-part-B">
          <div className="stocks-all">
            <Media greaterThanOrEqual="notebook">
              <Breadcrumbs links={links} />
            </Media>
            <h1 className="page-title">{pageName}</h1>
            {stocks.length > 0 && (
              <>
                <Media className="stocks-tile" greaterThanOrEqual="tablet">
                  {stocks.map((stock) => this.renderItem(stock, false))}
                </Media>
                <Media className="stocks-tile" lessThan="tablet">
                  {stocks.map((stock) => this.renderItem(stock, true))}
                </Media>
              </>
            )}
            {isSubscribeBlock && <SubscribeForm blockView />}
          </div>
          <CustomText className="custom-text-wrap" />
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ countries }) => ({
  mainDomain: countries.country.main_domain,
});

export default connect(mapStateToProps)(ssr(Stocks));
