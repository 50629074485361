import './Info.scss';

import './InfoMobile.scss';
import autobind from 'autobind-decorator';
import { Component } from 'react';
import axios from 'axios';
import * as session from 'modules/session';
import moment from 'moment';
import {
  modalEmitter,
  orderEmitter,
  userEmitter,
  userProfileEmitter,
} from 'modules/event-emitters';
import ResponseError from 'modules/response/error';
import { withTranslation } from 'react-i18next';
import UserGifts from 'frontend/Components/UserGifts/UserGifts';
import { analytics } from 'frontend/analytics/analytics';
import { UserPromoCode } from 'src/entities/promocode';
import Button from 'src/shared/ui/Button/Button';
import Radio from 'src/shared/ui/Radio/Radio';
import Favorites from '../../Favorites/Favorites';
import OrderTable from '../Order/Order';
import ReorderModal from '../../Modals/ReorderModal/ReorderModal';
import AddressEditModal from './Modals/AddressEdit';
import AddressDeleteModal from './Modals/AddressDelete';
import Emitter from '../../NewCartOrder/Emitters';

class ProfileInfo extends Component {
  // @todo - to locale
  DATE_FORMAT = 'DD.MM.YYYY';

  constructor(props) {
    super(props);
    const { user } = session.get() || {};
    this.state = {
      user,
      checked: user.address_id,
      isNotEmptyUserGifts: true,
    };
  }

  /// @todo !!!! вынести все в один файл
  componentDidMount() {
    orderEmitter.addListener('Order.Reorder', this.reorder);
    orderEmitter.addListener('Order.Reorder.New', this.reorderNew);
    orderEmitter.addListener('Order.Reorder.Add', this.reorderAdd);
  }

  componentWillReceiveProps(props) {
    const { checked } = this.state;
    const { addresses = [] } = props;

    if (!checked && addresses.length) {
      this.setState({
        checked: props.addresses && props.addresses[0].id,
      });
    }
  }

  componentWillUnmount() {
    orderEmitter.removeListener('Order.Reorder', this.reorder);
    orderEmitter.removeListener('Order.Reorder.New', this.reorderNew);
    orderEmitter.removeListener('Order.Reorder.Add', this.reorderAdd);
  }

  @autobind
  editHandler(e) {
    if (e) e.preventDefault();

    document.body.scrollTop = 0;

    userProfileEmitter.emit('User.Profile.ChangeTypeAction', 'edit');
  }

  logoutHandler(e) {
    e.preventDefault();

    axios
      .post('/auth/logout')
      .then(() => {
        session.set('user', undefined);
        userEmitter.emit('User.Change');
        userEmitter.emit('User.Logout.Event');
      })
      .catch((error) => {
        console.log('logout request failed');
        console.log(error);
        return false;
      });
    userEmitter.emit('Profile.MenuMobile.logout');
    return true;
  }

  @autobind
  checkedHandler({ target }) {
    const { id } = target;
    const { user = {} } = session.get();

    this.setState({
      checked: id,
    });

    axios
      .post('/user/set_addresses', { id })
      .then(() => {
        user.address_id = id;
        this.setState({ user });
        analytics.addressAction();
      })
      .catch(() => {
        if (user.address_id) delete user.address_id;
        this.setState({ user });
      });
  }

  @autobind
  addressEdit(address, e) {
    e.preventDefault();
    userProfileEmitter.emit('User.Address.Edit', address);
  }

  @autobind
  setIsNotEmptyUserGifts(isNotEmptyUserGifts) {
    this.setState({ isNotEmptyUserGifts });
  }

  @autobind
  addressDelete(address, e) {
    e.preventDefault();
    userProfileEmitter.emit('User.Address.Delete', address);
  }

  // reorder

  @autobind
  reorder(order) {
    this.reorderHandler(order);
  }

  @autobind
  reorderNew(order) {
    this.reorderHandler(order, true);
  }

  @autobind
  reorderAdd(order) {
    this.reorderHandler(order, false);
  }

  reorderHandler(order, isNew = null) {
    if (this.state.inProcess) {
      return true;
    }

    const form = {
      order_hash: order.hash,
      order_salt: order.salt,
    };

    if (typeof isNew !== 'undefined' || isNew !== null) {
      form.is_new = isNew;
    }

    this.inProcess(true);
    axios
      .post('/order/reorder', form)
      .then(() => {
        this.inProcess(false);
        window.location.href = '/cart';
      })
      .catch((error) => {
        modalEmitter.emit('Reorder.Modal.Close');
        this.inProcess(false);
        const responseError = new ResponseError(error);
        modalEmitter.emit('Modal.Error.Open', responseError.getMessage());
      });
  }

  inProcess(toggle) {
    this.setState({
      inProcess: !!toggle,
    });
  }

  openDeleteUserModal(e) {
    e.preventDefault();
    Emitter.emit('MODAL_CALL', { modal: 'DeleteUserModal' });
  }

  render() {
    const { user, checked } = this.state;
    const { updateAddress, addresses = [], t } = this.props;
    const orders = this.props.orders ? this.props.orders.slice(0, 5) : [];
    const { isMobile } = session.get();
    const { isNotEmptyUserGifts } = this.state;
    return (
      <div id="user-profile" className="user-profile">
        <div className="user-profile__item">
          <div className="input-box">
            <div className="input-container">
              <label>{t('Info.name')}</label>
              <p>{user.name ? user.name : t('Info.name_not_specified')}</p>
            </div>
            <div className="input-container">
              <label>{t('Info.birthday')}</label>
              <p>
                {user.birth_day
                  ? moment(user.birth_day).format(this.DATE_FORMAT)
                  : t('Info.birthday_not_specified')}
              </p>
            </div>
          </div>
          <div className="button__container">
            {!isMobile ? (
              <div className="button__case">
                <Button
                  className="sw-button"
                  onClick={this.editHandler}
                  style={{ width: '100%' }}
                  type="button"
                  text={t('Info.edit')}
                />
              </div>
            ) : undefined}
          </div>
          {isNotEmptyUserGifts && (
            <div className="block-products__products-group block-products_user-gift mt">
              <div className="products-group__title cart-title">
                <h2 className="cart-title page-title">
                  {t('BlockProducts.user_gift')}
                </h2>
              </div>
              <UserGifts
                isExact
                setIsNotEmptyUserGifts={this.setIsNotEmptyUserGifts}
              />
            </div>
          )}
          <UserPromoCode />
          {addresses.length ? (
            <div className="input-container">
              <label className="user-profile__address-label">
                {t('Info.main_address')}
              </label>

              <div className="user-profile__address">
                {addresses.map((address) => (
                  <Radio
                    id={address.id}
                    key={`address${address.id}`}
                    addClass="user-profile__radio"
                    checked={+address.id === +checked}
                    name={address.id}
                    onChange={this.checkedHandler}
                  >
                    {address.address}
                    {address.title ? (
                      <div className="user-profile__radio__title">
                        <p>{address.title}</p>
                      </div>
                    ) : undefined}
                    <div className="user-profile__radio__edit">
                      <span onClick={this.addressEdit.bind(this, address)}>
                        {t('Info.edit')}
                      </span>
                      <span
                        className="user-profile__radio__delete"
                        onClick={this.addressDelete.bind(this, {
                          address,
                          updateAddress,
                        })}
                      >
                        {t('Info.delete')}
                      </span>
                    </div>
                  </Radio>
                ))}
              </div>
            </div>
          ) : undefined}

          <div className="link-container">
            {isMobile ? (
              <div className="link">
                <a className="classToLink" href="/" onClick={this.editHandler}>
                  {t('Info.edit')}
                </a>
              </div>
            ) : undefined}
            <div className="link">
              <a className="classToLink" href="/" onClick={this.logoutHandler}>
                {t('Info.exit')}
              </a>
            </div>
            <div className="link">
              <a
                className="classToLink"
                href="/"
                onClick={this.openDeleteUserModal}
              >
                {t('Info.deleteUser')}
              </a>
            </div>
          </div>
          {!isMobile && (
            <div className="favorites-container">
              <p className="menu-container__header">
                {' '}
                {t('Info.last order')}{' '}
                {orders.length ? ` (${orders.length})` : ''}{' '}
              </p>
              <div className="order__container__table">
                {orders.length ? (
                  <table className="order-table">
                    <thead className="order-table__items-header">
                      <tr>
                        <th className="order-table__num-header">
                          {t('Info.order_number_header')}
                        </th>
                        <th className="order-table__status-header">
                          {t('Info.order_status_header')}
                        </th>
                        <th className="order-table__sum-header">
                          {t('Info.order_sum_header')}
                        </th>
                        <th className="order-table__date-header">
                          {t('Info.order_date_header')}
                        </th>
                      </tr>
                    </thead>
                    {orders.map((order, index) => (
                      <OrderTable key={`order-${index}`} order={order} />
                    ))}
                  </table>
                ) : (
                  <p className="order__container__default">
                    {t('Info.when_you_make')},<br />{' '}
                    {t('Info.order_will_shown')}
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
        {!isMobile && (
          <div className="favorites-container">
            <p className="menu-container__header"> {t('Info.Favorites')} </p>
            <Favorites />
          </div>
        )}
        <ReorderModal />
        <AddressEditModal />
        <AddressDeleteModal />
      </div>
    );
  }
}

export default withTranslation()(ProfileInfo);
