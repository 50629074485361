import { useTranslation } from 'react-i18next';
import styles from './callCenterInfo.module.scss';

interface ICallCenterInfoProps {
  phone: string;
}

function CallCenterInfo({ phone }: ICallCenterInfoProps) {
  const { t } = useTranslation();
  return (
    <div>
      {t(`OrderInfo.call to sw phone`)}&nbsp;
      <a className={styles.phoneLink} href={`tel:${phone}`}>
        {phone}
      </a>
    </div>
  );
}

export default CallCenterInfo;
