import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import globalStore from 'modules/global-store';
import { eventBus } from 'modules/event-emitters';
import YMapsProvider from 'app/providers/yandex-maps/YMapsProvider';
import App from 'app/App';
import 'app/styles/global.scss';
import { loadableReady } from '@loadable/component';
import { StoreProvider } from 'app/providers/store-provider';
import { I18nProvider } from 'app/providers/i18next-provider';
import { CookiesProvider } from 'react-cookie';
import storeDataManager from './frontend/storeDataManager';

declare global {
  interface Window {
    eventBus: any; // Replace 'any' with the appropriate type for your event bus
    dataLayerITRZ: any;
    ym: any;
    gtag: any;
    dataLayer: any;
    dataLayerAll: any;
    fbq: any;
    __PRELOADED_STATE__: any;
    initialI18nStore: any;
    initialLanguage: any;
    __SUSHIWOK__: any;
  }
}

const setMobilePredicat = () => {
  const width = window.innerWidth;
  const session = globalStore.get('session');
  globalStore.set('session', { ...session, isMobile: width <= 1024 });
};
storeDataManager.init();
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.eventBus = eventBus;
// eslint-disable-next-line no-underscore-dangle
window.__SUSHIWOK__ = {
  city: () => {
    const city = globalStore.get('current_city');
    return city.text_id;
  },
};
window.dataLayer = window.dataLayer || [];
window.dataLayer.push({
  event: 'app_init',
});
eventBus.emitEvent('app_init');
window.dataLayerITRZ = window.dataLayerITRZ || [];
window.dataLayerITRZ.push({
  event: 'app_init',
});

storeDataManager
  .asyncFill()
  .then(() => {
    window.dataLayerITRZ.push({
      event: 'products_loaded',
    });
  })
  .catch((e) => console.error(e));

window.addEventListener('resize', () => {
  setMobilePredicat();
});
window.addEventListener('DOMContentLoaded', () => {
  setMobilePredicat();
});
const params = globalStore.get('params');

function BaseApp() {
  return (
    <CookiesProvider>
      <BrowserRouter>
        <StoreProvider>
          <I18nProvider>
            <YMapsProvider>
              <App params={params} />
            </YMapsProvider>
          </I18nProvider>
        </StoreProvider>
      </BrowserRouter>
    </CookiesProvider>
  );
}

loadableReady(() => {
  ReactDOM.hydrate(<BaseApp />, document.getElementById('root'));
});
