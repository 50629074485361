import './organizationContactInformation.scss';
import { Helmet } from 'react-helmet';
import * as session from 'modules/session';

const getInformation = () => {
  const isWindowExist = typeof window !== 'undefined';
  if (!isWindowExist) return null;
  const { order: { organizationContactInformation } = {} } =
    session.get('cart');
  return organizationContactInformation;
};

export default function () {
  const organizationContactInformation = getInformation();

  return (
    <div className="page-container">
      <Helmet title="Юридическое лицо" />
      <div className="contactInformationWrap">
        {organizationContactInformation && (
          <div
            dangerouslySetInnerHTML={{ __html: organizationContactInformation }}
          />
        )}
      </div>
    </div>
  );
}
