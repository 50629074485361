import { useEffect, useState } from 'react';
import Button from 'src/shared/ui/Button/Button';
import { useTranslation } from 'react-i18next';
import styles from './cookie.module.scss';

function Cookie() {
  const { t } = useTranslation();
  const [isShown, setShow] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem('PRIVACY_POLICY')) {
      setShow(true);
    }
  }, []);
  const handleClose = () => {
    localStorage.setItem('PRIVACY_POLICY', 'true');
    setShow(false);
  };

  if (!isShown) return null;

  return (
    <div className={styles.root}>
      <p>{t('Main.cookie')}</p>
      <Button
        type="button"
        onClick={handleClose}
        className="sw-button"
        text={t('locale.accept')}
      />
    </div>
  );
}

export default Cookie;
