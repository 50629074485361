import './PickupMap.scss';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BallonImage from 'assets/images/icon/ballon-icon.svg';
import UaLocationIcon from 'assets/images/icon/uaLocationIcon.svg';
import { useSelectedCountry } from 'src/entities/countries';
import { Suggest } from 'features/address-suggest';
import { PickupMap as PickupMapWrapper } from 'features/stores-map';
import {
  IStore,
  storeHelper,
  useGetStoresListQuery,
} from 'src/entities/stores';
import { useSelectedStoreId } from 'src/entities/pickup';
import { useCityAndLang } from 'shared/lib/use-city-and-lang';

function PickupMap({
  onSelectStore,
}: {
  onSelectStore: (store: IStore) => void;
}) {
  const { isUkraine } = useSelectedCountry();
  const { t } = useTranslation();
  const selectedStoreId = useSelectedStoreId();

  const [currentStoreId, setCurrentStoreId] = useState<number | null>(
    selectedStoreId
  );

  const params = useCityAndLang();

  const { data: stores } = useGetStoresListQuery(params);

  const LocatioIcon = isUkraine ? UaLocationIcon : BallonImage;

  const currentStore = stores?.find((store) => store.id === currentStoreId);

  const getTexts = () => {
    if (!currentStore) return { infoText: '', buttonText: '' };
    if (storeHelper.isStillClosed(currentStore)) {
      return {
        infoText: t('Map.still_closed'),
        buttonText: t('Map.will_take_here'),
      };
    }
    if (storeHelper.isAlreadyClosed(currentStore)) {
      return {
        infoText: t('Map.already_closed'),
        buttonText: '',
      };
    }
    if (storeHelper.isWillCloseSoon(currentStore)) {
      return {
        infoText: t('Map.will_close_soon'),
        buttonText: '',
      };
    }
    return {
      infoText: '',
      buttonText: t('Map.will_take_here'),
    };
  };

  const { infoText, buttonText } = getTexts();

  return (
    <div className="pickup-map">
      <div className="pickup-map__toolbar">
        <div className="pickup-map__toolbar__left">
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
          <div
            className="geolocation-wrap"
            role="button"
            tabIndex={0}
            // onClick={getPosition}
            style={{ display: 'inline-block' }}
          >
            <LocatioIcon />
          </div>
          <div className="pickup-map__suggest">
            <div className="pickup-map__label">
              {t('PickupMap.your_address')}
            </div>
            <Suggest
              // fullAddress={fullAddress}
              // onChange={setCurrentAddress}
              placeholder={t('PickupMap.specify_street')}
            />
          </div>
        </div>
      </div>
      <div className="pickup-map__content">
        <PickupMapWrapper
          stores={stores || []}
          actionFn={onSelectStore}
          currentStoreId={currentStoreId}
          setCurrentStoreId={setCurrentStoreId}
          infoText={infoText}
          buttonText={buttonText}
        />
      </div>
    </div>
  );
}

export default PickupMap;
