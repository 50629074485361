import './BlockProducts.scss';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { route } from 'modules/route';
import * as session from 'modules/session';
import globalStore from 'modules/global-store';
import productHelper from 'modules/helpers/product-helper';
import { useTranslation } from 'react-i18next';
import { analytics } from 'frontend/analytics/analytics';
import { getProductIds } from 'modules/helpers/cart-helper';
import api from 'modules/helpers/api';
import { userEmitter } from 'modules/event-emitters';
import { isEmpty } from 'lodash';
import { useSelectedCountry, useCurrency } from 'src/entities/countries';
import { Complectation } from 'src/entities/complectation';
import { InfoButton } from 'features/comlectation-info';
import cn from 'classnames';
import { ScrollAnchor } from 'src/shared/ui/scroll-anchor/ScrollAnchor';
import { complectationAnchor } from 'src/entities/complectation/model/constants';
import type { ICart } from 'types/cartTypes';
import { SmartLink } from 'shared/ui/smart-link';
import CartProducts from './CartProducts/CartProducts';
import CartGifts from './CartGifts/CartGifts';
import RecommendedProducts from './RecommendedProducts/RecommendedProducts';
import Emitter from '../../Emitters';
import * as cartHelper from '../../../../../modules/helpers/cart-helper';
import UserGifts from '../../../UserGifts/UserGifts';
import { userBonusFields } from './scheme';
import styles from './blockProducts.module.scss';
import CartAdditionals from './CartAdditionals/CartAdditionals';

const handleBlurField = ({ target }: React.FocusEvent<HTMLFormElement>) => {
  cartHelper.sendOrderField(target.name, target.value);
  if (target.value) {
    analytics.deliveryFieldsAction(
      target.name as
        | 'phone'
        | 'apartment'
        | 'comment'
        | 'entrance'
        | 'floor'
        | 'address'
        | 'name'
        | 'email'
    );
  }
};

const fillValues = (
  { promo_code: promocode, bonus_phone: bonus, order }: ICart,
  fields: ReturnType<typeof userBonusFields>
) => {
  return fields.map((field) => {
    let value = (order && (order as any)[field.name]) || '';
    if (field.name === 'promocode' && promocode) value = promocode;
    if (field.name === 'phone' && bonus) value = bonus;
    return {
      value,
      ...field,
    };
  });
};
function CartProductsBlock() {
  const { t } = useTranslation();
  const { isExact } = useRouteMatch();
  const cart: ICart = session.get('cart');
  const { isMontenegro } = useSelectedCountry();

  const fields = fillValues(cart, userBonusFields({ isMontenegro, t }));
  const [isLogin, setIsLogin] = useState(() => {
    const { user } = session.get() || {};
    return !isEmpty(user);
  });
  const { complectations } = session.get('cart');
  const [isNotEmptyGifts, setIsNotEmptyGifts] = useState(true);
  const currency = useCurrency();
  const history = useHistory();
  const location = useLocation();

  const handleChangeForm = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const resetCart = () => {
    Emitter.emit('MODAL_CALL', { modal: 'ResetCartModal' });
  };

  const current_city = globalStore.get('current_city');
  const { text_id } = current_city;
  const additional = globalStore.get('additionally');
  const { items = [], total, total_orig } = cart;

  const isProducts = !!items.filter(
    (item) => !!productHelper.getCartProduct(item.product_id)
  ).length;
  const isDiscount = total < total_orig;
  const isAdditional = additional && additional.length > 0;
  const isCartLocation = location.pathname === `/${text_id}/cart/`;
  const isShowUserGifts = isLogin && isNotEmptyGifts;

  useEffect(() => {
    const onCartChange = (args: { notChangeUrl?: boolean }) => {
      const { notChangeUrl = false } = args || {};
      if (isExact || notChangeUrl) {
        return;
      }
      history.push(route('cart'));
    };

    const setUserIsLogin = async () => {
      const ids = getProductIds(session.get('cart'));
      const products = await api('product.get', { id: ids });
      globalStore.set('cart_products', products);
      setIsLogin(true);
    };

    Emitter.addListener('CART_CHANGE', onCartChange);
    userEmitter.addListener('User.Login.Event', setUserIsLogin);
    return () => {
      Emitter.removeListener('CART_CHANGE', onCartChange);
      userEmitter.removeListener('User.Login.Event', setUserIsLogin);
    };
  }, [history, isExact]);

  const titleWithContainer = cn(styles.title, styles.titleContainer);
  return (
    <div className={styles.blockProducts}>
      <div className={styles.editCartContainer}>
        {!isExact ? (
          <SmartLink className={styles.editCart} href="/cart">
            {t('BlockProducts.edit_cart')}
          </SmartLink>
        ) : (
          <button type="button" className={styles.editCart} onClick={resetCart}>
            {t('BlockProducts.reset_cart')}
          </button>
        )}
      </div>
      {isProducts && (
        <div className={styles.cartProductsBlock}>
          <CartProducts {...{ isExact }} />
        </div>
      )}
      {isProducts && isExact && (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <form
          className="new-cart__form"
          onKeyDown={handleChangeForm}
          onInput={handleChangeForm}
          onPaste={handleChangeForm}
          onChange={handleChangeForm}
          onBlur={handleBlurField}
        >
          {fields.map(({ Component, ...field }) => {
            if (!Component) return null;
            return (
              <Component
                handleChangeForm={handleChangeForm}
                field={field}
                key={field.name}
              />
            );
          })}
        </form>
      )}
      {!!complectations?.length && (
        <div>
          <ScrollAnchor
            name={complectationAnchor}
            className={styles.titleContainer}
          >
            <h2 className={styles.title}>{t('Complectation.complectation')}</h2>
            <InfoButton />
          </ScrollAnchor>
          <Complectation isExact={isExact} available={complectations} />
        </div>
      )}
      {isShowUserGifts && (
        <div>
          <h2 className={titleWithContainer}>
            {!isExact
              ? t('BlockProducts.your_user_gift')
              : t('BlockProducts.add_gifts')}
          </h2>
          <UserGifts
            isExact={isExact}
            setIsNotEmptyUserGifts={setIsNotEmptyGifts}
          />
        </div>
      )}
      <CartGifts isExact={isExact} />
      {isAdditional && isCartLocation && (
        <div>
          <h2 className={titleWithContainer}>
            {t('BlockProducts.your_additional')}
          </h2>
          <CartAdditionals additional={additional} />
        </div>
      )}
      <RecommendedProducts />
      <div
        className={`block-products__total-container${
          !isExact ? ' is-children' : ''
        }`}
      >
        <p>
          {isExact ? t('BlockProducts.total_order') : t('BlockProducts.cart')}
        </p>
        <p>
          <strong>
            <span>
              {total}&nbsp;{currency}
            </span>
            {isDiscount && (
              <small>
                {total_orig}&nbsp;{currency}
              </small>
            )}
          </strong>
        </p>
      </div>
    </div>
  );
}

export default CartProductsBlock;
