import { LinkTabs } from 'shared/ui/link-tabs';
import globalStore from 'modules/global-store';
import { route } from 'modules/route';
import { DeliveryCityAvg } from 'src/entities/delivery';
import { useTranslation } from 'react-i18next';
import { DeliveryCheck } from 'features/delivery-check';
import { ICityInfo } from 'types/cityInfo';
import CustomText from 'src/frontend/Components/CustomText/CustomText';
import ErrorComponent from 'src/frontend/Components/ErrorComponent/ErrorComponent';
import Breadcrumbs from 'src/frontend/Components/Breadcrumbs/Breadcrumbs';
import CustomHelmet from 'src/frontend/Components/CustomHelmet';
import { useCurrency } from 'src/entities/countries';
import { DeliveryMap } from 'features/delivery-map';
import cn from 'classnames';
import { Suggest } from 'features/address-suggest';
import { Media } from 'shared/lib/media';
import DeliveryHeader from './delivery-header/DeliveryHeader';
import styles from './delivery.module.scss';

function Delivery() {
  const { t } = useTranslation();
  const tabsItems = [
    {
      url: `/delivery`,
      title: t('localization.Delivery'),
    },
    {
      url: `/addresses`,
      title: t('localization.Stores'),
    },
  ];
  const { is_delivery } = globalStore.get('current_city');
  const cityInfo: ICityInfo = globalStore.get('city_info');

  const currency = useCurrency();

  const { delivery, delivery_card: deliveryCard } = cityInfo;
  const deliveryAvgProps = {
    priceFrom: deliveryCard.delivery_price,
    priceTo: delivery.delivery_price,
    orderMin: delivery.order_min,
    timeFrom: delivery.from ?? '-',
    timeTo: delivery.to ?? '-',
    currency,
  };

  if (!is_delivery) {
    return <ErrorComponent />;
  }

  const links = [
    {
      href: route('/'),
      text: t('localization.Main'),
    },
    {
      text: t('localization.Delivery'),
    },
  ];

  return (
    <>
      <CustomHelmet title={t('localization.Delivery')} />
      <div className="page-container">
        <div className={cn('page-delivery', styles.pageDelivery)}>
          <div className="inner-part-B">
            <Media greaterThan="laptop">
              <Breadcrumbs links={links} />
            </Media>
            <div className={styles.pageDeliveryBlock}>
              <div className={styles.pageDeliverySearch}>
                <DeliveryHeader />
                <DeliveryCityAvg {...deliveryAvgProps} />
                <div className={styles.address}>
                  <div className={styles.deliveryCheck}>
                    <Media greaterThanOrEqual="notebook">
                      <span className={styles.addressTitle}>
                        {t('Delivery.your_address')}
                      </span>
                    </Media>
                    <DeliveryCheck
                      suggest={
                        <Suggest
                          placeholder={t('localization.Enter street or subway')}
                        />
                      }
                    />
                  </div>
                </div>
              </div>
              <div className={styles.deliveryRoutes}>
                <LinkTabs items={tabsItems} />
              </div>
            </div>
            <div className={styles.deliveryMap}>
              <DeliveryMap />
            </div>
            <CustomText className={styles.customTextWrap} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Delivery;
