import './Order.scss';
import { Component } from 'react';
import autobind from 'autobind-decorator';
import { ranks } from 'modules/common';
import moment from 'moment';
import { modalEmitter } from 'modules/event-emitters';
import { withTranslation } from 'react-i18next';
import { withCurrency } from 'src/entities/countries';

class Order extends Component {
  @autobind
  openModal() {
    const { order } = this.props;
    modalEmitter.emit('Reorder.Modal.Show', order);
  }

  renderStatus() {
    const { order, t } = this.props;
    switch (order.status_id) {
      case 5:
        return t('Order.delivered');
      case 6:
        return t('Order.canceled');
      case 2:
        return t('Order.preparing');
      case 4:
        return t('Order.delivering');
      case 1:
        return t('Order.accepted');
      default:
    }
  }

  @autobind
  renderTable() {
    const { order, t, currency } = this.props;
    const noPrice = order.items.reduce((s, a) => {
      return s + a.price;
    }, 0);

    const items = order.items.reduce((s, item) => {
      if (!item.parent_id && item.price !== 0 && item.product_id !== 1139) {
        return s + item.qty;
      }
      return s;
    }, 0);

    return (
      <tr data-id={order.id} onClick={this.openModal}>
        <td className="order-table__body__number">
          <span className="order-table__body__color--black">
            № {order.hash}
          </span>
        </td>
        <td>
          <span
            className={`order-table__body__color${
              order.status_id === 6 ? '' : '--common-1'
            }`}
          >
            {this.renderStatus()}
          </span>
        </td>
        <td>
          <span className="order-table__body__color">
            {t('Order.positions')}:
            <span className="order-table__body__color--black">
              {` ${items}`}
            </span>
          </span>
        </td>
        <td className="order-table__price">
          <span className="order-table__body__color">
            <span className="order-table__body__sum">{t('Order.sum')}:</span>
            {noPrice ? (
              <span className="order-table__body__color--black">
                {` ${ranks(order.total + order.total_dlv)}`}
                &nbsp;
                {currency}
              </span>
            ) : (
              <span className="order-table__body__color--black"> -</span>
            )}
          </span>
        </td>
        <td>
          <span className="order-table__body__time">
            {moment(order.created_at).format('HH:mm')}
          </span>
          <span className="order-table__body__color">
            {moment(order.created_at).format('DD.MM.YY')}
          </span>
        </td>
      </tr>
    );
  }

  render() {
    return <tbody className="order-table__body">{this.renderTable()}</tbody>;
  }
}

export default withTranslation()(withCurrency(Order));
