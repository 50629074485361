import './Vacancies.scss';
import { Component } from 'react';
import { vacanciesEmitter } from 'modules/event-emitters';
import { route } from 'modules/route';
import globalStore from 'modules/global-store';
import * as session from 'modules/session';
import api from 'modules/helpers/api';
import ssr from 'modules/decorators/ssr';
import kashierPreview from './assets/kashier.png';
import courierPreview from './assets/courier.png';
import { allowedCities } from './cities';
import VacancyVideo from '../../Components/Vacancies/vacancyVideo/VacancyVideo';
import VacanciesModal from '../../Components/Vacancies/VacanciesModal/VacanciesModal';
import VacanciesInfo from '../../Components/Vacancies/VacancyInfo/VacanciesInfo';
import VacancyContactsLine from '../../Components/Vacancies/VacancyContactsLine/VacancyContactsLine';
import VacancyCommentLine from '../../Components/Vacancies/VacancyCommentLine/VacancyCommentLine';
import VacancyCard from '../../Components/Vacancies/VacancyCard/VacancyCard';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs';
import VacancyCardMobile from '../../Components/Vacancies/VacancyCard/VacancyCardMobile';
import VacancyCommentLineMobile from '../../Components/Vacancies/VacancyCommentLine/VacancyCommentLineMobile';
import VacanciesInfoMobile from '../../Components/Vacancies/VacancyInfo/VacanciesInfoMobile';
import VacanciesContactLineMobile from '../../Components/Vacancies/VacancyContactsLine/VacancyContactsLineMobile';
import VacanciesModalMobile from '../../Components/Vacancies/VacanciesModal/VacanciesModalMobile';
import CustomText from '../../Components/CustomText/CustomText';
import CustomHelmet from '../../Components/CustomHelmet';

class Vacancies extends Component {
  static LOADED_KEY = 'vacancies_loaded';

  static initialData = (fetch, params, globalStore) => {
    return fetch('vacancies')
      .then((vacancies) => globalStore.set('vacancies', vacancies))
      .catch((e) => {
        console.error('Vacancies', e);
        return [];
      });
  };

  constructor(props) {
    super(props);
    this.state = {
      showOverlay: false,
      cellInRow: 3,
      vacancies: globalStore.get('vacancies'),
    };
  }

  componentDidMount() {
    if (globalStore.get(Vacancies.LOADED_KEY)) {
      globalStore.unlink(Vacancies.LOADED_KEY);
    } else {
      Vacancies.initialData(api, {}, globalStore).then(() => {
        this.setState({
          vacancies: globalStore.get('vacancies', []),
        });
      });
    }

    vacanciesEmitter.addListener(
      'Vacancies.Card.showOverlay',
      this.showOverlay
    );
    vacanciesEmitter.addListener(
      'Vacancies.Card.hideOverlay',
      this.hideOverlay
    );

    window.addEventListener('resize', this.checkSize);
    window.addEventListener('load', this.checkSize);
  }

  componentWillUnmount() {
    vacanciesEmitter.removeListener(
      'Vacancies.Card.showOverlay',
      this.showOverlay
    );
    vacanciesEmitter.removeListener(
      'Vacancies.Card.hideOverlay',
      this.hideOverlay
    );

    window.removeEventListener('resize', this.checkSize);
    window.removeEventListener('load', this.checkSize);
  }

  getTitle() {
    const { t } = this.props;
    return t('Vacancies.vacancies_title');
  }

  checkSize = () => {
    const documentWidth = document.documentElement.offsetWidth;

    if (documentWidth < 1200) return this.setState({ cellInRow: 0 });
    if (documentWidth > 1200) return this.setState({ cellInRow: 3 });
  };

  showOverlay = () => {
    // from mobile page
    // document.body.style.overflow = 'hidden';

    this.setState({
      showOverlay: true,
    });
  };

  hideOverlay = () => {
    // from mobile page
    // document.body.style.overflow = '';

    this.setState({
      showOverlay: false,
    });

    vacanciesEmitter.emit('Vacancies.Card.Unrotate');
  };

  renderMobile(isAllowedVacancyCity) {
    const { vacancies } = this.state;
    const { t } = this.props;
    const current_city = globalStore.get('current_city');
    const { vacancy_email } = current_city || '';

    const vacanciesFirst = vacancies.slice(0, 3);
    const vacanciesLast = vacancies.slice(3);

    const contentFirst = [];
    const contentLast = [];

    vacanciesFirst.forEach((vacancy, index) => {
      contentFirst.push(
        <VacancyCardMobile
          vacancyEmail={vacancy_email}
          vacancy={vacancy}
          vacancies={vacancies}
          key={`vacancy-f-${index}`}
        />
      );
    });

    vacanciesLast.forEach((vacancy, index) => {
      contentLast.push(
        <VacancyCardMobile
          vacancyEmail={vacancy_email}
          vacancy={vacancy}
          vacancies={vacancies}
          key={`vacancy-f-${index}`}
        />
      );
    });

    return (
      <div className="page-container page-vacancies">
        <CustomHelmet title={this.getTitle()} type="article" />
        <div className="inner-part-B">
          <div className="vacancies-component">
            <div>
              <h1 className="page-title">{t('Vacancies.vacancies_title')}</h1>
              <div>
                <div className="vacancies-component__list">{contentFirst}</div>
                <VacancyCommentLineMobile
                  key="comment-text-1"
                  shortname="comment1"
                />
                <div className="vacancies-component__list">{contentLast}</div>
              </div>
              <VacanciesInfoMobile />
              {isAllowedVacancyCity && (
                <div className="vacancies-videos">
                  <VacancyVideo
                    title="О работе Продавца-кассира"
                    videoLink="//www.youtube.com/embed?v=F_zSiITCLoA&list=PLcRZ4YQMIWWu0W_fgqlYtj3AdtD4qrr5K"
                    preview={kashierPreview}
                    isMobile
                  />
                  <VacancyVideo
                    title="О вакансии Курьер"
                    videoLink="//www.youtube.com/embed/AVneBkYZ2aw"
                    preview={courierPreview}
                    isMobile
                  />
                </div>
              )}
              <VacanciesContactLineMobile vacancyEmail={vacancy_email} />
              <VacanciesModalMobile />
            </div>
            <CustomText className="custom-text-wrap" />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { showOverlay, cellInRow, vacancies } = this.state;
    const { t } = this.props;
    const { vacancy_email, text_id } = globalStore.get('current_city');
    const isAllowedVacancyCity = allowedCities.includes(text_id);

    if (!vacancies) {
      return <div />;
    }

    if (session.get('isMobile')) {
      return this.renderMobile(isAllowedVacancyCity);
    }

    const links = [
      {
        href: route('/'),
        text: t('localization.Main'),
      },
      {
        text: t('Vacancies.vacancies_title'),
      },
    ];

    const vacanciesFirst = vacancies.slice(0, 3);
    const vacanciesLast = vacancies.slice(3);

    const contentFirst = [];
    const contentLast = [];

    vacanciesFirst.forEach((vacancy, index) => {
      contentFirst.push(
        <VacancyCard
          vacancyEmail={vacancy_email}
          vacancy={vacancy}
          vacancies={vacancies}
          index={index}
          key={`vacancy-f-${index}`}
        />
      );
    });

    vacanciesLast.forEach((vacancy, index) => {
      contentLast.push(
        <VacancyCard
          vacancyEmail={vacancy_email}
          vacancy={vacancy}
          vacancies={vacancies}
          index={index + vacanciesFirst.length}
          key={`vacancy-f-${index}`}
        />
      );
    });

    return (
      <div className="page-container">
        <CustomHelmet title={this.getTitle()} type="article" />
        <div className="inner-part-B" style={{ minHeight: '1000px' }}>
          <div className="vacancies-component">
            <Breadcrumbs links={links} />
            <div>
              <h1 className="page-title">{t('Vacancies.vacancies_title')}</h1>

              {cellInRow ? (
                <div>
                  <div className="vacancies-component__list">
                    {contentFirst}
                  </div>
                  <VacancyCommentLine
                    key="comment-text-1"
                    shortname="comment1"
                  />
                  <div className="vacancies-component__list">{contentLast}</div>
                </div>
              ) : (
                <div>
                  <div className="vacancies-component__list">
                    {contentFirst}
                    {contentLast}
                  </div>
                </div>
              )}
              <div
                className={`vacancies-component__overlay ${
                  showOverlay ? 'vacancies-component__overlay--shown' : ''
                }`}
                onClick={this.hideOverlay}
              />
              <VacanciesInfo />
              {isAllowedVacancyCity && (
                <div className="vacancies-videos">
                  <VacancyVideo
                    title="О работе Продавца-кассира"
                    videoLink="//www.youtube.com/embed?v=F_zSiITCLoA&list=PLcRZ4YQMIWWu0W_fgqlYtj3AdtD4qrr5K"
                    preview={kashierPreview}
                  />
                  <VacancyVideo
                    title="О вакансии Курьер"
                    videoLink="//www.youtube.com/embed/AVneBkYZ2aw"
                    preview={courierPreview}
                  />
                </div>
              )}
              <VacancyContactsLine vacancyEmail={vacancy_email} />
              <VacanciesModal />
            </div>
          </div>
          <CustomText className="custom-text-wrap" />
        </div>
      </div>
    );
  }
}

export default ssr(Vacancies);
