import { renderRoutes } from 'react-router-config';
// import Hamster from 'frontend/widgets/hamster/Hamster';
import SupportChat from 'frontend/widgets/support-chat/SupportChat';
import Cookie from 'frontend/widgets/cookie/Cookie';
import { MediaContextProvider } from 'src/shared/lib/media';
import { ErrorBoundary } from 'react-error-boundary';
import Error from 'src/shared/ui/error';
import { useResetCartOnChangeCity } from 'features/cart-reset';
import {
  useFirstShowConfirmCityModal,
  useCityChange,
} from 'features/geopositioning';
import routes from './routes';
import { Theme } from './theme';
import SwRegister from './sw-register';

function App({ params }: { params: { [key: string]: string } }) {
  const { hasCityChanged } = useCityChange();
  useResetCartOnChangeCity(hasCityChanged);
  useFirstShowConfirmCityModal();
  return (
    <MediaContextProvider>
      <Theme />
      <SwRegister />
      <ErrorBoundary FallbackComponent={Error}>
        {renderRoutes(routes, { params, routes })}
        {/* <Hamster /> */}
        <Cookie />
        <SupportChat />
      </ErrorBoundary>
    </MediaContextProvider>
  );
}

export default App;
