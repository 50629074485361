import {
  addFree,
  complectationSliceSelectors,
} from 'src/entities/complectation/model/slice';
import { useAppDispatch, useAppSelector } from 'shared/lib/store';
import { IProduct } from 'types/product';
import { useTranslation } from 'react-i18next';
import styles from './complectation.module.scss';
import ComplectationItem from './complectation-item/ComplectationItem';

interface IComplectationFreeView {
  products: IProduct[];
  isExact: boolean;
}

function DontWantButton({ id, qty }: { id: number; qty: number }) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  if (!qty) return null;
  return (
    <button
      type="button"
      onClick={() => dispatch(addFree({ id, qty }))}
      className={styles.moreButton}
    >
      {t('Complectation.dontWant')}
    </button>
  );
}

function ComplectationFree({ isExact, products }: IComplectationFreeView) {
  const free = useAppSelector(complectationSliceSelectors.selectFree);
  const availiable = useAppSelector(
    complectationSliceSelectors.selectAvailable
  );

  const dispatch = useAppDispatch();

  if (!availiable) return null;

  const setQty = (id: number, qty: number) => {
    dispatch(addFree({ id, qty }));
  };

  return (
    <section>
      {availiable.map(({ id, qty, max }) => {
        if (!id || !max) return null;
        const product = products.find(({ id: pId }) => id === pId);
        if (!product) return null;
        const currentQty = free[id]?.qty ?? qty;
        return (
          <ComplectationItem
            key={product.id}
            product={product}
            qty={currentQty}
            isExact={isExact}
            summPrice={<DontWantButton id={id} qty={currentQty} />}
            moreAvaliable={currentQty >= 0 ? currentQty < max : false}
            setQty={setQty}
          />
        );
      })}
    </section>
  );
}

export default ComplectationFree;
