import './Profile.scss';

import { Component } from 'react';
import { renderRoutes } from 'react-router-config';

// const { route } = require('modules/route');
import { Helmet } from 'react-helmet';

// modules
import * as session from 'modules/session';

import { userEmitter } from 'modules/event-emitters';

// const Breadcrumbs = require('../../Components/Breadcrumbs/Breadcrumbs');

// Components
// import Menu from '../../Components/Profile/Menu/Menu';

import { withTranslation } from 'react-i18next';
import RegisterOrAuth from '../../Components/NewCartOrder/Modals/Components/register-auth-modal/RegisterOrAuth';

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: session.get('user'),
    };
  }

  componentDidMount() {
    userEmitter.addListener('User.Logout.Event', this.userLoginHandler);
    userEmitter.addListener('User.Login.Event', this.userLoginHandler);
    userEmitter.addListener('User.Change', this.userRegisteredHandler);
  }

  componentWillUnmount() {
    userEmitter.removeListener('User.Logout.Event', this.userLoginHandler);
    userEmitter.removeListener('User.Login.Event', this.userLoginHandler);
    userEmitter.removeListener('User.Change', this.userRegisteredHandler);
  }

  userLoginHandler = () => {
    this.setState({
      user: session.get('user'),
    });
  };

  userRegisteredHandler = () => {
    this.setState({
      user: session.get('user'),
    });
  };

  renderAuth() {
    return (
      <div className="auth-page main-cart-modal">
        <RegisterOrAuth view="profile" />
      </div>
    );
  }

  renderContent() {
    const { user } = this.state;
    const { params, route: { routes = [] } = {} } = this.props;
    if (!user) {
      return this.renderAuth();
    }

    return renderRoutes(routes, { params });
  }

  render() {
    const { user } = this.state;
    const { t } = this.props;
    return (
      <div className="page-container">
        <div className="profile-container">
          <div className="inner-part-B">
            <div className="profile-container__menu-container menu-list-wrap">
              {user && (
                <h1 className="menu-container__header">
                  {' '}
                  {t('AbstractProfile.profile')}{' '}
                </h1>
              )}
            </div>
            <div className="profile__container">
              <Helmet title={t('AbstractProfile.profile')} />
              {this.renderContent()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Profile);
