import { baseApi } from 'src/shared/api';
import { IStore, IStoresFromApi } from '../model/types';
import storeHelper from '../lib/store-helper';

const getStoresListResponseHandling = (data: {
  data: { stores: IStoresFromApi[] };
}): IStore[] => {
  const { stores } = data.data;
  return storeHelper.flatMetro(stores);
};

export const storeInfoApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getStoreInfo: build.query<
      { data: { store: IStore } },
      { city: number; id: number }
    >({
      query: (query) => ({
        url: `/endpoint/store/get_store_by_id`,
        method: 'GET',
        params: query,
      }),
    }),
    getStoresList: build.query<IStore[], { city: string; lang: string }>({
      query: (query) => ({
        url: `/endpoint/store/get_list_city`,
        method: 'GET',
        params: query,
      }),
      transformResponse: getStoresListResponseHandling,
    }),
  }),
});

export const { useGetStoreInfoQuery, useGetStoresListQuery } = storeInfoApi;
