import { useTranslation } from 'react-i18next';
import { LinkTabs } from 'shared/ui/link-tabs';
import Favorites from 'src/frontend/Components/Favorites/Favorites';

function FavoritesPage() {
  const { t } = useTranslation();
  const tabsItems = [
    {
      url: `/profile`,
      title: t('Menu.profile'),
    },
    {
      url: `/profile/orders`,
      title: t('Menu.my_orders'),
    },
    {
      url: `/profile/favorites`,
      title: t('Menu.favorites'),
    },
  ];
  return (
    <div className="profile__page">
      <LinkTabs items={tabsItems} />
      <Favorites />
    </div>
  );
}

export default FavoritesPage;
